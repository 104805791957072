(function (exports) {
    var _
    var mS
    if (typeof window !== 'undefined') {
        _ = window._
    } else {
        _ = require("../../../node_modules/underscore")
        mS = require("../services/models")
    }


    var shirtBack = {
        name: "Back", //make this an enum of "Front", "Left", "Right", "Back"
        defaultDesignerImage: "../images/designerDefault/defaultBackShirt.svg"
    }

    var defaultFront = {
        name: "Front",
        defaultDesignerImage: ""
    }


    var shirtLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/leftSleeve.svg"
    }

    var shortSleeveLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/shortSleeveLeft.svg"
    }

    var shortSleeveRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/shortSleeveRight.svg"
    }

    var shortSleeveBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/shortSleeveBack.svg"
    }

    var shirtRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/rightSleeve.svg"
    }

    var hatBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/hatBack.svg"
    }

    var bagLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/blankSquareBack.svg"
    }

    var bagRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/blankSquareBack.svg"
    }

    var drinkWareBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/drinkWareBack.svg"
    }


    var hatLeft = {
        name: "Left",
        defaultDesignerImage: "../images/designerDefault/hatLeft.svg"
    }

    var golfBallBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/golfBallBack.svg"
    }

    var hatRight = {
        name: "Right",
        defaultDesignerImage: "../images/designerDefault/hatRight.svg"
    }

    var toqueBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/toqueBack.svg"
    }

    var bucketHatBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/bucketHatBack.svg"
    }


    var blankSquareBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/blankSquareBack.svg"
    }

    var toteBagBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/toteBagBack.svg"
    }

    var notebookBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/notebookBack.svg"
    }

    var mugBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/mugBack.svg"
    }


    var pantsBack = {
        name: "Back",
        defaultDesignerImage: "../images/designerDefault/pantsBack.svg"
    }


    //**
    // filtering facets
    // facet metadata

    var listOfAllFits = [
        'Unisex',
        'Ladies',
        'Youth',
        'Toddler'
    ]

    var facetDefinitionsMap = {
        f_material: {
            name: 'Material',
            key: 'f_material',
            multiple: true,

            shoudSplitPunctuation: true,
        },
        f_bagFeatures: {
            key: 'f_bagFeatures',
            name: 'Bag Features',
            multiple: true
        },
        f_bagGussets: {
            key: 'f_bagGussets',
            name: 'Gusseted',
            manualAssign: true,
        },
        f_drinkwareFeature: {
            key: 'f_drinkwareFeature',
            name: 'Drinkware Features',
            multiple: true
        },
        f_penFeatures: {
            key: 'f_penFeatures',
            name: 'Pen Features',
            multiple: true
        },
        f_techFeatures: {
            key: 'f_techFeatures',
            name: 'Technology Features',
            multiple: true
        },
        f_productFeatures: {
            key: 'f_productFeatures',
            name: 'Product Features',
            multiple: true
        },
        f_garmentWeight: {
            key: 'f_garmentWeight',
            name: 'Weight'
        },
        f_topStyle: {
            key: 'f_topStyle',
            name: 'Style',
            multiple: true
        },
        f_garmentFit: {
            key: 'f_garmentFit',
            name: 'Fit'
        },
        f_bagSize: {
            key: 'f_bagSize',
            name: 'Bag Size',
            manualAssign: true
        },
        f_penClosure: {
            key: 'f_penClosure',
            name: 'Pen Opening Type',
        },
        f_penInkType: {
            key: 'f_penInkType',
            name: 'Pen Ink',
            multiple: true
        },
        f_bagClosure: {
            key: 'f_bagClosure',
            name: 'Bag Closure',
            manualAssign: true,
            multiple: true
        },
        f_topCollar: {
            key: 'f_topCollar',
            name: 'Neckline'
        },
        f_topPocket: {
            key: 'f_topPocket',
            name: 'Pockets',
            multiple: true
        },
        f_topSleeveLength: {
            key: 'f_topSleeveLength',
            name: 'Sleeve Length'
        },
        f_garmentTag: {
            key: 'f_garmentTag',
            name: 'Garment Tag',
            multiple: true
        },
        f_hatStructure: {
            key: 'f_hatStructure',
            name: 'Structure'
        },
        f_hatClosure: {
            key: 'f_hatClosure',
            name: 'Hat Closure',
        },
        f_hatProfile: {
            key: 'f_hatProfile',
            name: 'Hat Profile',
        },
        f_topCuffs: {
            key: 'f_topCuffs',
            name: 'Sleeve Cuffs',
            multiple: true
        },
        f_topHem: {
            key: 'f_topHem',
            name: 'Hem',
            multiple: true
        },
        f_legClosure:{
            key: 'f_legClosure',
            name: 'Leg Bottom',
            multiple: true
        },
        f_jacketHood: {
            key: 'f_jacketHood',
            name: 'Hood',
            manualAssign: true
        },
        f_topClosure: {
            key: 'f_topClosure',
            name: 'Fastener',
            manualAssign: true,
            multiple: true
        },
        f_bagTechSleeve: {
            key: 'f_bagTechSleeve',
            name: 'Max Tech Sleeve Size'
        },
        f_drinkwareConstruction: {
            key: 'f_drinkwareConstruction',
            name: 'Drinkware Construction'
        },
        f_drinkwareLid: {
            key: 'f_drinkwareLid',
            name: 'Drinkware Opening'
        },
        f_journalPages: {
            key: 'f_journalPages',
            name: 'Page Type'
        },
        f_journalBinding: {
            key: 'f_journalBinding',
            name: 'Notebook Binding'
        },
        f_journalClosure: {
            key: 'f_journalClosure',
            name: 'Notebook Closure'
        },
        f_journalNumPages: {
            key: 'f_journalNumPages',
            name: "Number Of Pages"
        },
        // safety wear
        f_safetyHighVisClass: {
            key: 'f_safetyHighVisClass',
            name: 'High-Visibility Safety Classes',
            multiple: true
        },
        f_safetyHighVisType: {
            key: 'f_safetyHighVisType',
            name: "High-Visibility Safety Types",
            manualAssign: true,
            multiple: true
        }
    }


    // default product
    var defaultFacets = [
        facetDefinitionsMap.f_material.key,
        facetDefinitionsMap.f_productFeatures.key,
    ]
    var journalFacets = [
        ...defaultFacets,
        facetDefinitionsMap.f_journalPages.key,
        facetDefinitionsMap.f_journalBinding.key,
        facetDefinitionsMap.f_journalClosure.key,
        facetDefinitionsMap.f_journalNumPages.key,
    ]

    var bagFacets = [
            ...defaultFacets,
        facetDefinitionsMap.f_garmentWeight.key,
        facetDefinitionsMap.f_bagTechSleeve.key,
        facetDefinitionsMap.f_bagClosure.key,
        facetDefinitionsMap.f_bagSize.key,
        facetDefinitionsMap.f_bagFeatures.key,
        facetDefinitionsMap.f_bagGussets.key,
    ]

    var drinkwareFacets = [
            ...defaultFacets,
        facetDefinitionsMap.f_drinkwareFeature.key,
        facetDefinitionsMap.f_drinkwareLid.key,
        facetDefinitionsMap.f_drinkwareConstruction.key
    ]

    var techFacets = [
        ...defaultFacets,
        facetDefinitionsMap.f_techFeatures.key
    ]

    var penFacets = [
            ...defaultFacets,
        facetDefinitionsMap.f_penFeatures.key,
        facetDefinitionsMap.f_penClosure.key,
        facetDefinitionsMap.f_penInkType.key
    ]

    var garmentFacets = [
        ...defaultFacets,
        facetDefinitionsMap.f_garmentWeight.key,
        facetDefinitionsMap.f_garmentTag.key
    ]

    var garmentTopFacets = [
        ...garmentFacets,
        facetDefinitionsMap.f_garmentFit.key,
        facetDefinitionsMap.f_topSleeveLength.key,
        facetDefinitionsMap.f_topStyle.key,
        facetDefinitionsMap.f_topCollar.key,
        facetDefinitionsMap.f_topPocket.key,
        facetDefinitionsMap.f_topCuffs.key,
        facetDefinitionsMap.f_topHem.key,
        facetDefinitionsMap.f_topClosure.key
    ]

    var jacketFacets = [
        ...garmentTopFacets,
        facetDefinitionsMap.f_jacketHood.key,
    ]

    var garmentBottomFacets = [
        ...garmentFacets,
        facetDefinitionsMap.f_legClosure.key
    ]

    var sublimationFacets = Array.from(new Set([
            ...garmentTopFacets,
            ...garmentBottomFacets
        ]))


    var headwearFacets = [
       ...garmentFacets,
        facetDefinitionsMap.f_hatStructure.key,
        facetDefinitionsMap.f_hatClosure.key,
        facetDefinitionsMap.f_hatProfile.key,
    ]

    var safetyTopFacets = [
        ...garmentTopFacets,
        facetDefinitionsMap.f_safetyHighVisClass.key,
        facetDefinitionsMap.f_safetyHighVisType.key
    ]

    var safetyBottomFacets = [
        ...garmentBottomFacets,
        facetDefinitionsMap.f_safetyHighVisClass.key,
        facetDefinitionsMap.f_safetyHighVisType.key
    ]

    var safetyHeadwearFacets = [
        ...headwearFacets,
        facetDefinitionsMap.f_safetyHighVisClass.key,
        facetDefinitionsMap.f_safetyHighVisType.key
    ]

    var defaultCategory = {
        name: "Default",
        designerMultiplier: 1,
        screenPrintAllowed: true,
        printAllowed: {screenPrint: true, transfer: true},
        defaultWeight: 0.8,
        printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
        facets: defaultFacets
    }

    var fitRootPath = {
        ladies: '/custom-products/custom-clothing/ladies',
        youth: '/custom-products/custom-clothing/youth',
        toddler: '/custom-products/custom-clothing/youth/toddlers'
    }

    var allCategories = [
        defaultCategory,
        {
            name: "Short Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/t-shirts/short-sleeve',
                ladies: fitRootPath.ladies + '/t-shirts',
                youth: fitRootPath.youth + '/t-shirts'
            },
            hsCode: '6109.10.0004',
            facets: garmentTopFacets
        },
        {
            name: "Long Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/t-shirts/long-sleeve',
                ladies: fitRootPath.ladies + '/long-sleeves',
                youth: fitRootPath.youth + '/long-sleeves'
            },
            hsCode: '6109.10.0004',
            facets: garmentTopFacets
        },
        {
            name: "Tank Tops",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/t-shirts/tank-tops',
                ladies: fitRootPath.ladies + '/tank-tops',
                youth: fitRootPath.youth + '/all',
                toddler: fitRootPath.youth + '/toddlers',
            },
            hsCode: '6109.10.0004',
            facets: garmentTopFacets
        },
        {
            name: "Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters/hoodies',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/hoodies',
            },
            hsCode: '6110.20.20.10',
            facets: garmentTopFacets
        },
        {
            name: "Fleece Quarter Zip Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/fleece',
                ladies: fitRootPath.ladies + '/jackets/fleece',
                youth: fitRootPath.youth + '/jackets',
            },
            facets: jacketFacets,
            hsCode: '6110.20.20.10',

        },
        {
            name: "Quarter Zip Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters/quarter-zip-sweatshirts',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/sweatshirts',
            },
            facets: garmentTopFacets,
            hsCode: '6110.20.20.10',

        },
        {
            name: "Full Zip Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters/full-zip-sweatshirts',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/sweatshirts',
            },
            facets: garmentTopFacets,
            hsCode: '6110.20.20.10',

        },
        {
            name: "Winter Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/winter',
                ladies: fitRootPath.ladies + '/jackets/winter',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Wind Breakers",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/lightweight',
                ladies: fitRootPath.ladies + '/jackets/lightweight-windbreakers',
                youth: fitRootPath.youth + '/jackets'
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "3 in 1 System Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/3-in-1-system',
                ladies: fitRootPath.ladies + '/jackets/3-in-1-system',
                youth: fitRootPath.youth + '/jackets'
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets

        },
        {
            name: "5 in 1 Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/jackets/5-in-1-system',
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/5-in-1-system',
                ladies: fitRootPath.ladies + '/jackets/3-in-1-system',
                youth: fitRootPath.youth + '/jackets'
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Anorak Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/anorak',
                youth: fitRootPath.youth + '/jackets'
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Raincoats",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/raincoat',
                ladies: fitRootPath.ladies + '/jackets/raincoat',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets

        },
        {
            name: "Accessories",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            hsCode: '6214.30.0000',
            facets: defaultFacets
        },
        {
            name: "Kilt",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            hsCode: '6204.53.30.10',
            facets: garmentBottomFacets
        },
        {
            name: "Sweatpants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/pants-bottoms/sweatpants',
                ladies: fitRootPath.ladies + '/sweatpants-shorts',
                youth: fitRootPath.youth + '/sweatpants-shorts',
            },
            hsCode: '6110.20.20.10',
            facets: garmentBottomFacets
        },
        {
            name: "Soft Shell Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/soft-shell',
                ladies: fitRootPath.ladies + '/jackets/soft-shell',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Polos",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/polos/short-sleeves',
                ladies: fitRootPath.ladies + '/polos',
                youth: fitRootPath.youth + '/polos',
            },
            hsCode: '6105.10.0010',
            facets: garmentTopFacets
        },
        {
            name: "Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/jackets/vests',
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/vests',
                ladies: fitRootPath.ladies + '/jackets/vests',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Tote Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/tote-bags',
            hsCode: '4202.92.1500',
            facets: bagFacets
        },
        {
            name: "Backpacks",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-backpacks',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Flat Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/hats/flat-brimmed',
            hsCode: '6505.00.15.40',
            facets: headwearFacets,
        },
        {
            name: "Curved Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/hats/dad-hats',
            hsCode: '6505.00.15.40',
            facets: headwearFacets,
        },
        {
            name: "Toques",
            designerMultiplier: 5,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toqueBack],
            categoryUrl: '/custom-products/custom-clothing/hats/toques',
            hsCode: '6505.00.15.40',
            facets: headwearFacets,
        },
        {
            name: "Fleece Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/fleece',
                ladies: fitRootPath.ladies + '/jackets/fleece',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6110.20.20.10',
            facets: jacketFacets,

        },
        {
            name: "Fleece Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/vests',
                ladies: fitRootPath.ladies + '/jackets/vests',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets,
        },
        {
            name: "Puffer Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/puffer',
                ladies: fitRootPath.ladies + '/jackets/puffer',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Zip Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters/zip-hoodies',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/sweatshirts',
            },
            hsCode: '6110.20.20.10',
            facets: garmentTopFacets

        },
        {
            name: "Shorts",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/pants-bottoms/shorts',
                ladies: fitRootPath.ladies + '/sweatpants-shorts',
                youth: fitRootPath.youth + '/sweatpants-shorts',
            },
            hsCode: '6103.42.10.50',
            facets: garmentBottomFacets
        },
        {
            name: "Long Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/button-downs',
                youth: fitRootPath.youth + '/all',
            },
            hsCode: '6105.10.0010',
            facets: garmentTopFacets
        },
        {
            name: "Short Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/button-downs',
                youth: fitRootPath.youth + '/all',
            },
            hsCode: '6105.10.0010',
            facets: garmentTopFacets
        },
        {
            name: "Crewneck Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters/crewneck-sweatshirts',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/sweatshirts',
            },
            hsCode: '6110.20.20.10',
            facets: garmentTopFacets

        },
        {
            name: "Uniforms",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear',
            hsCode: '6103.22.00.10',
            facets: garmentTopFacets
        },
        {
            name: "Long Sleeve Polos",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/polos/long-sleeves',
                ladies: fitRootPath.ladies + '/polos',
                youth: fitRootPath.youth + '/polos',
            },
            hsCode: '6105.10.0010',
            facets: garmentTopFacets
        },
        //todo: fix sublimation bread crumb
        {
            name: "Sublimation",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/jerseys/sublimated-apparel/all',
            hsCode: '6114.30.3060',
            facets: sublimationFacets
        },
        {
            name: "Luggage",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Golf Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Fanny Packs",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-fanny-packs',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Casual Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/casual',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        // {
        //     name: "Leather Jackets",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: false, transfer: false},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
        //     categoryUrl: '/custom-products/custom-clothing/jackets/all'
        // },
        {
            name: "Laptop Bags",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/custom-laptop-bags',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Casual Sweaters",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters/casual-sweaters',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/sweatshirts',
            },
            hsCode: '6110.20.20.10',
            facets: garmentTopFacets
        },
        {
            name: "Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/pants-bottoms/pants',
                youth: fitRootPath.youth + '/all',
            },
            hsCode: '6103.42.10.50',
            facets: garmentBottomFacets
        },
        {
            name: "Safety Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/pants',
            hsCode: '6110.20.20.10',
            facets: garmentBottomFacets
        },
        {
            name: "Puffer Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/vests',
                ladies: fitRootPath.ladies + '/jackets/vests',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Roller Bag",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-roller-bags',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Safety Uniforms",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/overalls-coveralls',
            hsCode: '6103.22.00.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Short Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shortSleeveBack, shortSleeveLeft, shortSleeveRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/t-shirts',
            hsCode: '6109.10.0004',
            facets: safetyTopFacets
        },
        {
            name: "Safety Long Sleeve T Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/long-sleeve',
            hsCode: '6109.10.0004',
            facets: safetyTopFacets
        },
        {
            name: "Safety Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/hoodies',
            hsCode: '6110.20.20.10',
            facets: safetyTopFacets
        },
        {
            name: "Zippered Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/sweatshirts',
            },
            hsCode: '6110.20.20.10',
            facets: garmentTopFacets

        },
        {
            name: "Safety Zippered Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/zip-hoodies',
            hsCode: '6110.20.20.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Sweatpants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/pants',
            hsCode: '6110.20.20.10',
            facets: safetyBottomFacets
        },
        {
            name: "Lightweight Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/lightweight',
                ladies: fitRootPath.ladies + '/jackets/lightweight-windbreakers',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Safety Lightweight Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Soft Shell Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/vests',
            hsCode: '6210.30.50.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Flat Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety',
            hsCode: '6505.00.15.40',
            facets: safetyHeadwearFacets
        },
        {
            name: "Safety Curved Brim Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety',
            hsCode: '6505.00.15.40',
            facets: safetyHeadwearFacets
        },
        {
            name: "Safety Toques",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toqueBack],
            categoryUrl: '/custom-products/custom-clothing/hats/toques/embroidered',
            hsCode: '6505.00.15.40',
            facets: safetyHeadwearFacets
        },
        {
            name: "Safety Fleece Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6110.20.20.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Fleece Vests",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/vests',
            hsCode: '6110.20.20.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Puffer Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10',
            facets: safetyTopFacets
        },
        {
            name: "Insulated Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/insulated',
                ladies: fitRootPath.ladies + '/jackets/insulated',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Safety Insulated Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/jackets',
            hsCode: '6210.30.50.10',
            facets: safetyTopFacets
        },
        {
            name: "Safety Zip Hoodies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/zip-hoodies',
            hsCode: '6110.20.20.10',
            facets: safetyTopFacets

        },
        {
            name: "Safety Shorts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/pants',
            hsCode: '6110.20.20.10',
            facets: safetyBottomFacets

        },
        {
            name: "Safety Long Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/long-sleeve',
            hsCode: '6105.10.0010',
            facets: safetyTopFacets
        },
        {
            name: "Safety Short Sleeve Button Down Shirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/t-shirts',
            hsCode: '6105.10.0010',
            facets: safetyTopFacets
        },
        {
            name: "Safety Crewneck Sweatshirts",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/safety/hoodies',
            hsCode: '6110.20.20.10',
            facets: safetyTopFacets

        },
        {
            name: "Track Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/track',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6110.20.20.10',
            facets: jacketFacets

        },
        {
            name: "Bucket Hats",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, bucketHatBack],
            categoryUrl: '/custom-products/custom-clothing/hats/bucket-hats',
            hsCode: '6505.00.15.40',
            facets: headwearFacets
        },
        {
            name: "Leggings",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms',
            hsCode: '6115.22.0000',
            facets: garmentBottomFacets
        },
        {
            name: "Dress",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            // categoryUrl: '/custom-products/custom-clothing/dresses',
            hsCode: '6204.42.3060',
            facets: garmentTopFacets
        },
        {
            name: "Bras",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: fitRootPath.ladies + '/all',
            hsCode: '6212.10.9020',
            facets: defaultFacets
        },
        {
            name: "Cinch Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-bags/custom-cinch-bags',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Duffle Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, bagRight, bagLeft, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/duffel-bags',
            hsCode: '4202.92.9100',
            facets: bagFacets

        },
        {
            name: "Collared Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/casual',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Varsity Jackets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/varsity',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Scarves",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/scarves/embroidered-scarves',
            hsCode: '6214.30.0000',
            facets: defaultFacets
        },
        {
            name: "Lunch Coolers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, bagRight, bagLeft, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/coolers',
            hsCode: '4202.92.9100',
            facets: bagFacets

        },
        {
            name: "Laptop and Tablet Cases",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/custom-laptop-tablet-sleeves',
            hsCode: '4202.92.9100',
            facets: garmentTopFacets
        },
        {
            name: "Drink and Bottle Bags",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/custom-bags/custom-drink-and-bottle-bags',
            hsCode: '4202.92.1500',
            facets: garmentTopFacets
        },
        {
            name: "Aprons",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront,],
            categoryUrl: '/custom-products/custom-clothing/work-wear/restaurant/aprons',
            hsCode: '6103.22.00.10',
            facets: defaultFacets
        },
        {
            name: "Chef Coats",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/restaurant/chef-coats',
            hsCode: '6103.22.00.10',
            facets: garmentTopFacets
        },
        {
            name: "Bomber Jacket",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/jackets/bomber-jacket',
                youth: fitRootPath.youth + '/jackets',
            },
            hsCode: '6210.30.50.10',
            facets: jacketFacets
        },
        {
            name: "Overall",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack],
            categoryUrl: '/custom-products/custom-clothing/pants-bottoms/overalls',
            hsCode: '6103.42.20.25',
            facets: safetyTopFacets
        },
        {
            name: "Visor",
            designerMultiplier: 4,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, hatBack, hatLeft, hatRight],
            categoryUrl: '/custom-products/custom-clothing/hats/visors',
            hsCode: '6505.00.15.40',
            facets: headwearFacets
        },
        {
            name: "Blankets",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/blankets',
            hsCode: '6301.40.0010',
            facets: defaultFacets
        },
        {
            name: "Socks",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/custom-clothing/socks/basic-print',
            hsCode: '6115.30.9010',
            facets: defaultFacets
        },
        {
            name: "Chef Wear",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/custom-clothing/work-wear/restaurant',
            hsCode: '6103.22.00.10',
            facets: garmentTopFacets
        },
        {
            name: "Dog Wear",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            hsCode: '4201.00.6000',
            facets: defaultFacets
        },
        // {
        //     name: "Blouse",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront]
        // },
        // {
        //     name: "Pyjamas",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront]
        // },
        {
            name: "Golf Towels",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/jerseys/sports/golf/towels',
            hsCode: '6302.60.0020',
            facets: defaultFacets
        },
        {
            name: "Sports Towels",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/jerseys/sports/towels',
            hsCode: '6302.60.0020',
            facets: defaultFacets
        },
        {
            name: "Beach Towels",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/towels/beach-towels',
            hsCode: '6302.60.0020',
            facets: defaultFacets
        },
        {
            name: "Hand Towels",
            designerMultiplier: 2,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/towels/hand-towels',
            hsCode: '6302.60.0020',
            facets: defaultFacets
        },
        {
            name: "Bath Towels",
            designerMultiplier: 1,
            printAllowed: {screenPrint: false, transfer: false},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/towels/bath-towels',
            hsCode: '6302.60.0020',
            facets: defaultFacets
        },
        {
            name: "Cardigans",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/hoodies-sweaters/casual-sweaters',
                ladies: fitRootPath.ladies + '/sweatshirts',
                youth: fitRootPath.youth + '/sweatshirts'
            },
            hsCode: '6110.20.20.10',
            facets: garmentTopFacets

        },
        // {
        //     name: "Knit Sweaters",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
        //     categoryUrl: '/custom-products/custom-clothing/hoodies-sweaters'
        // },
        // {
        //     name: "Jumpers",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight]
        // },
        // {
        //     name: "Skirt",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, pantsBack],
        //     categoryUrl: '/custom-products/custom-clothing/ladies'
        // },
        // {
        //     name: "Skort",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, pantsBack],
        //     categoryUrl: '/custom-products/custom-clothing/ladies'
        // },
        {
            name: "Short Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            catUrlMap: {
                unisex: '/custom-products/custom-clothing/pants-bottoms/shorts',
                youth: fitRootPath.youth + '/sweatpants-shorts'
            },
            hsCode: '6103.42.10.50',
            facets: garmentBottomFacets
        },
        {
            name: "Athletic Pants",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, pantsBack],
            catUrlMap: {
                unisex: '/custom-products/jerseys/pants',
                youth: fitRootPath.youth + '/jerseys/pants-shorts'
            },
            hsCode: '6114.30.3060',
            facets: garmentBottomFacets
        },
        // {
        //     name: "Blazers",
        //     designerMultiplier: 1,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
        //     categoryUrl: '/custom-products/custom-clothing/jackets'
        // },
        {
            name: "Bibs",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/custom-clothing/youth/all',
            hsCode: '6111.20.6020',
            facets: defaultFacets
        },
        {
            name: "Onesies",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/youth/onesies',
            hsCode: '6111.20.6020',
            facets: garmentTopFacets
        },
        {
            name: "Masks",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/face-masks',
            hsCode: '9505.90.60.00',
            facets: defaultFacets
        },
        {
            name: "Gloves",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            hsCode: '6116.93.94.00',
            facets: defaultFacets
        },
        {
            name: "Journals",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.25,
            printLocations: [defaultFront, notebookBack],
            categoryUrl: '/custom-products/promotional/office-supplies/notebooks',
            hsCode: '4820.10.20.20',
            facets: journalFacets
        },
        {
            name: "Bottles",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, drinkWareBack],
            categoryUrl: '/custom-products/promotional/drinkware/custom-bottles',
            hsCode: '7615.10.91.00',
            facets: drinkwareFacets
        },
        {
            name: "Tumblers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, mugBack],
            categoryUrl: '/custom-products/promotional/drinkware/tumblers',
            hsCode: '7615.10.91.00',
            facets: drinkwareFacets
        },
        {
            name: "Mugs",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, mugBack],
            categoryUrl: '/custom-products/promotional/drinkware/mugs',
            hsCode: '6912.00.4400',
            facets: drinkwareFacets
        },
        {
            name: "Insulated Mugs",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, mugBack],
            categoryUrl: '/custom-products/promotional/drinkware/mugs',
            hsCode: '7615.10.91.00',
            facets: drinkwareFacets
        },
        {
            name: "Bottle Openers",
            designerMultiplier: 4,
            defaultWeight: 0.1,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/bottle-opener',
            hsCode: '7616.99.51.60',
            facets: defaultFacets
        },
        {
            name: "Pens",
            designerMultiplier: 3,
            defaultWeight: 0.025,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/office-supplies/custom-pens',
            hsCode: '9608.10.0000',
            facets: penFacets
        },
        {
            name: "Stickers",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products',
            hsCode: '4911.91.2040',
            facets: defaultFacets
        },
        {
            name: "Patches",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.1,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/patches',
            hsCode: '5810.91.0010',
            facets: defaultFacets
        },
        {
            name: "Padfolios",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/office-supplies/padfolio',
            hsCode: '4820.10.20.20',
            facets: bagFacets
        },
        {
            name: "USB",
            designerMultiplier: 4,
            defaultWeight: 0.025,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/usb',
            hsCode: '8523.51.0000',
            facets: techFacets
        },
        {
            name: "Chargers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/portable-chargers',
            hsCode: '8504.40.8500',
            facets: techFacets
        },
        {
            name: "Power Banks",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/portable-chargers',
            hsCode: '8504.40.8500',
            facets: defaultFacets
        },
        {
            name: "Headphones",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/tech/headphones',
            hsCode: '8518.30.2000',
            facets: techFacets
        },
        {
            name: 'Toys',
            designerMultiplier: 3,
            defaultWeight: 1,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            hsCode: '9503.00.00.90',
            facets: defaultFacets
        },
        {
            name: "Lanyards",
            designerMultiplier: 3,
            defaultWeight: 0.065,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/office-supplies/lanyards',
            hsCode: '6307.90.40.20',
            facets: defaultFacets
        },
        {
            name: 'Mousepads',
            designerMultiplier: 1,
            defaultWeight: 0.5,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            hsCode: '8473.30.91.00',
            facets: defaultFacets
        },
        {
            name: 'Pillows',
            designerMultiplier: 1,
            defaultWeight: 0.5,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, defaultFront],
            hsCode: '9404.90.10.30',
            facets: defaultFacets
        },
        {
            name: "Stress Relievers",
            designerMultiplier: 4,
            defaultWeight: 0.065,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/office-supplies/stress-balls',
            hsCode: '9503.00.00.90',
            facets: defaultFacets
        },
        {
            name: "Umbrellas",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/umbrellas',
            hsCode: '6601.10.0000',
            facets: defaultFacets
        },
        {
            name: "Sunglasses",
            designerMultiplier: 3,
            defaultWeight: 0.05,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/sunglasses',
            hsCode: '9004.10.00.00',
            facets: defaultFacets
        },
        {
            name: "Coasters",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/coasters',
            hsCode: '4419.90.91.00',
            facets: defaultFacets
        },
        {
            name: "Sticky Notes",
            designerMultiplier: 3,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: '/custom-products/promotional/office-supplies/sticky-notes',
            hsCode: '4820.10.20.20',
            facets: defaultFacets
        },
        {
            name: "Keychains",
            designerMultiplier: 4,
            defaultWeight: 0.05,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront],
            hsCode: '7616.99.51.60',
            facets: defaultFacets
        },
        {
            name: "Koozies",
            designerMultiplier: 4,
            defaultWeight: 0.02,
            printAllowed: {screenPrint: true, transfer: true},
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/drinkware/beer-koozies',
            hsCode: '3924.10.4000',
            facets: defaultFacets
        },
        {
            name: "Candles",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.5,
            printLocations: [defaultFront, blankSquareBack],
            categoryUrl: '/custom-products/promotional/outdoor-leisure/candles',
            hsCode: '3406.00.0000',
            facets: defaultFacets

        },
        {
            name: "Speakers",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront],
            categoryUrl: "/custom-products/promotional/tech/speakers",
            hsCode: '8517.62.0090',
            facets: techFacets
        },
        {
            name: "Non-woven Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/shopping/non-woven',
            hsCode: '4202.92.9100',
            facets: bagFacets

        },
        {
            name: "Grocery Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/shopping/grocery',
            hsCode: '4202.92.1500',
            facets: bagFacets
        },
        {
            name: "Recycled Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: "/custom-products/custom-bags/shopping/recycled",
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Laminated Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: '/custom-products/custom-bags/shopping/laminated',
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Insulated Shopping Bags",
            designerMultiplier: 2,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, toteBagBack],
            categoryUrl: "/custom-products/custom-bags/shopping/insulated",
            hsCode: '4202.92.9100',
            facets: bagFacets
        },
        {
            name: "Golf Balls",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 1,
            printLocations: [defaultFront, golfBallBack],
            categoryUrl: '/custom-products/jerseys/sports/golf/balls',
            hsCode: '9506.32.0000',
            facets: defaultFacets
        },
        {
            name: "Hockey Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/jerseys/sports/hockey',
                youth: fitRootPath.youth + '/jerseys/hockey'
            },
            hsCode: '6114.30.3060',
            facets: garmentTopFacets
        },
        {
            name: "Football Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/jerseys/sports',
                youth: fitRootPath.youth + '/jerseys/all'
            },
            hsCode: '6114.30.3060',
            facets: garmentTopFacets
        },
        {
            name: "Volleyball Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/jerseys/sports/volleyball',
                youth: fitRootPath.youth + '/jerseys/volleyball'
            },
            hsCode: '6114.30.3060',
            facets: garmentTopFacets
        },
        {
            name: "Basketball Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/jerseys/sports/basketball',
                youth: fitRootPath.youth + '/jerseys/basketball'
            },
            hsCode: '6114.30.3060',
            facets: garmentTopFacets
        },
        {
            name: "Baseball Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/jerseys/sports/baseball',
                youth: fitRootPath.youth + '/jerseys/baseball'
            },
            hsCode: '6114.30.3060',
            facets: garmentTopFacets
        },
        {
            name: "Soccer Jerseys",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            catUrlMap: {
                unisex: '/custom-products/jerseys/sports/soccer',
                youth: fitRootPath.youth + '/jerseys/soccer'
            },
            hsCode: '6114.30.3060',
            facets: garmentTopFacets
        },
        // {
        //     name: "Lunch Boxes",
        //     designerMultiplier: 4,
        //     printAllowed: {screenPrint: true, transfer: true},
        //     defaultWeight: 0.8,
        //     printLocations: [defaultFront, bagRight, bagLeft]
        // },
        {
            name: "Scrubs",
            designerMultiplier: 1,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.8,
            printLocations: [defaultFront, shirtBack, shirtLeft, shirtRight],
            categoryUrl: '/custom-products/custom-clothing/work-wear/scrubs',
            hsCode: '6103.22.00.10',
            facets: garmentTopFacets
        },
        {
            name: "Swag Boxes",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.5,
            printLocations: [defaultFront],
            hsCode: '4819.10.0040',
            facets: defaultFacets
        },
        {
            name: "Greeting Cards",
            designerMultiplier: 4,
            printAllowed: {screenPrint: true, transfer: true},
            defaultWeight: 0.06,
            printLocations: [defaultFront],
            hsCode: '4909.00.4000',
            facets: defaultFacets
        },
    ]

//DESIGNER STUFF AND TOOLS

// standard print locations
    var leftChest = 'Left Chest'
    var rightChest = 'Right Chest'
    var front = 'Front'
    var back = 'Back'
    var upperLeftSleeve = 'Left Sleeve'
    var upperRightSleeve = 'Right Sleeve'
    var leftCuff = 'Left Cuff'
    var rightCuff = 'Right Cuff'
    var backNape = 'Back Nape'
    var leftSide = 'Left Side'
    var rightSide = 'Right Side'
    var leftThigh = 'Left Thigh'
    var rightThigh = 'Right Thigh';

    var standardPrintLocations = [
        leftChest, rightChest, front, back, upperLeftSleeve, upperRightSleeve, leftCuff, rightCuff, backNape, leftSide, rightSide, leftThigh, rightThigh
    ]
    var standardEmbroideryFonts = [
        'Arial', 'Athletic', 'Constania', 'Microsoft Sans Serif', 'Market', 'Handy Script', 'Block 2', 'Old English Font', 'Oswald', 'Tinos', 'Script 2'
    ]

    function autoTagAllProductFacets(product, facetOptionsMap) {
        if (product.category) {
            var foundCategory = _.find(allCategories, function(cat) {
                return cat.name === product.category
            })
            if (foundCategory) {
                _.forEach(foundCategory.facets, function(facetKey) {
                    var foundFacet;
                    if (product.facets) {
                        foundFacet = _.find(product.facets, function(productFacet) {
                            return productFacet.key === facetKey
                        })
                    }
                    if (!foundFacet){
                        if (!product.facets) {
                            product.facets = []
                        }
                        product.facets.push({
                            key: facetKey,
                            selectedOptions: [],
                            manuallyAssigned: [],
                            blacklistOptions: []
                        })

                    }
                })
            }

            _.forEach(product.facets, function(productFacet){
                // keep manual assigned attributes
                productFacet.selectedOptions = _.filter(productFacet.selectedOptions, function(selectedOption) {
                    var manuallyAssigned = _.find(productFacet.manuallyAssigned, function(manual) {
                        return manual === selectedOption
                    })
                    return manuallyAssigned
                });
                var foundFacetDefinition = facetDefinitionsMap[productFacet.key];
                if (foundFacetDefinition) {
                    // console.log('Tagging product facet: ' + productFacet.key);
                    // console.log(productFacet);
                    if (facetOptionsMap) {
                        foundFacetDefinition.options = facetOptionsMap[productFacet.key]
                    } else {
                        foundFacetDefinition.options = [];
                    }
                    autoTagProductFacet(product, productFacet, foundFacetDefinition)
                } else {
                    console.error('did not find ' + productFacet.key)
                }
            });

            // keep filled out facets
            var filteredFacets =  _.filter(product.facets, function(productFacet) {
                return productFacet.selectedOptions.length > 0 || productFacet.blacklistOptions.length > 0
            })
            product.facets = filteredFacets;

        }
    }

    // phrase = [['drawcord','drawstring'], ['waist', 'hip']]
    // combinations =
    // 1) drawcord and waist
    // 2) drawcord and hip
    // 3) drawstring and waist
    // 4) drawstring and hip
    function getAllPhraseCombination(phrases) {
        var normalizedPhrases = phrases.map(keyword =>
            Array.isArray(keyword) ? keyword.map(k => k.toLowerCase()) : [keyword.toLowerCase()]
        );

        function cartesianProduct(arrays) {
            if (arrays.length === 0) return [[]]; // Base case: Return empty array
            const firstArray = arrays[0];
            const rest = cartesianProduct(arrays.slice(1));
            return firstArray.flatMap(item =>
                rest.map(combination => [item, ...combination])
            );
        }


        var combinations = cartesianProduct(normalizedPhrases);

        return combinations;
    }

    function getAllTaggableMetadata(product) {
        // 1. must test each description individually for existence of all text in phrase
        // within description, split by comma, periods and line breaks
        var descriptions = (_.map(product.description, function(descriptionLine) {
            return descriptionLine.split(/\.\s+|\n/)
        })).flat()
        var stringsToCheck = [product.name, product.category, ...descriptions, ...product.tags]
        stringsToCheck = _.map(stringsToCheck, function(stringPhrase) {
            return ' ' + stringPhrase.toLowerCase() + ' ';
        })
        return stringsToCheck;
    }

    function autoTagProductFacet(product, productFacet, facetDefinition) {
        if (facetDefinition.manualAssign) {
            // console.log(facetDefinition.name + ' facet attribute is manually assigned.');
            return;
        } else {
            var stringsToCheck = getAllTaggableMetadata(product);
            _.forEach(facetDefinition.options, function(facetAttribute) {
                var match;
                if (facetAttribute.noAutoAssign) {
                    // console.log(facetAttribute.name + ' no auto assign allowed');
                    return;
                }
                // blacklist certain categories
                if (facetAttribute.blacklistCategories && facetAttribute.blacklistCategories.includes(product.category)) {
                    // console.log(facetAttribute.name + ' do not match on category ' + product.category);
                    return;
                }
                // legacy
                if (facetAttribute.keywords) {
                    match = _.some(facetAttribute.keywords, function (keyword) {
                        return productHasKeyword(stringsToCheck, keyword.toLowerCase());
                    })
                } else if (facetAttribute.phrase) {

                    var combinations = getAllPhraseCombination(facetAttribute.phrase);
                    match = _.some(combinations, function (combination) {
                        return _.some(stringsToCheck, function (sentence) {
                            return _.every(combination, function (keyword) {
                                if (keyword) {
                                    return productHasKeyword([sentence], keyword, facetDefinition.shoudSplitPunctuation)
                                }
                            })

                        })
                    });
                } else {
                    // use product name
                    match = productHasKeyword(stringsToCheck, facetAttribute.name.toLowerCase())
                }

                if (match) {
                    if (productFacet.blacklistOptions) {
                        if (productFacet.blacklistOptions.includes(facetAttribute.name)){
                            // console.log(facetAttribute.name + ' is blacklisted');
                            return;
                        }
                    }
                    // console.log('Match on tag: ' + facetAttribute.name)
                    if (!facetDefinition.multiple && productFacet.selectedOptions.length > 0) {
                        // console.log('Already has a match - Skip tagging: ' + facetAttribute.name);
                        return;
                    }

                    if (productFacet.manuallyAssigned.includes(facetAttribute.name)) {
                        return;
                    }
                    // console.log(facetAttribute.name +  ' ' + facetDefinition.name);
                    productFacet.selectedOptions.push(facetAttribute.name);
                }
            })
        }
    }

    // should split punctuation - descriptors usually pack in multiple matching keywords within the same description line, split by punctuation ',' should
    // treat them as separate strings to check
    function productHasKeyword(stringsToCheck, keyword, shouldSplitPunctuation) {
        if (shouldSplitPunctuation) {
            stringsToCheck = (_.map(stringsToCheck, function(descriptionLine) {
                return descriptionLine.split(/, /)
            })).flat()
        }
        var match = _.some(stringsToCheck, function (sentence) {
            function isNegated(keyword) {
                var regex = new RegExp(`\\b(no|not)\\s+${keyword}\\b`, 'i');
                return regex.test(sentence);
            }
            return sentence.includes(keyword) && !isNegated(keyword)
        });

        return match
    }

    exports.standardPrintLocations = standardPrintLocations;
    exports.standardEmbroideryFonts = standardEmbroideryFonts;
    exports.allCategories = allCategories;
    exports.listOfAllFits = listOfAllFits;
    exports.defaultCategory = defaultCategory;
    exports.facetDefinitionsMap = facetDefinitionsMap;
    exports.autoTagAllProductFacets = autoTagAllProductFacets;
    exports.getAllPhraseCombination = getAllPhraseCombination;
    exports.getAllTaggableMetadata = getAllTaggableMetadata;
})(typeof exports === 'undefined' ?
    this['categoryService'] = {} : exports);
