(function () {
    'use strict';

    angular.module('sharedModule')
        .factory('SEOService', SEOService)

    SEOService.$inject = ['$rootScope', '$http', '$location', '$q', 'CommonUtilService', 'PageTemplateService', 'CountryService', '$window'];

    function SEOService($rootScope, $http, $location, $q, CommonUtilService, PageTemplateService, CountryService, $window) {

        // todo: remove from index and use these methods instead
        function setMetaTitle(title) {
            $window.document.title = title;
        }

        function setMetaDescription (description) {
            var meta = $window.document.getElementsByTagName("meta");
            for (var i = 0; i < meta.length; i++) {
                if (meta[i].name.toLowerCase() == "description") {
                    meta[i].content = description;
                }
            }
        };

        function setMetaImage(image) {
            var meta = $window.document.getElementsByTagName("meta");
            for (var i = 0; i < meta.length; i++) {
                if (meta[i].name.toLowerCase() == "og:image") {
                    meta[i].content = image;
                }
            }
        }

        function updateMetaTags(metaInfo) {
            var defaults = {
                title: 'Coastal Reign | Custom Printed Clothing and Swag',
                description: 'A Vancouver based provider of custom products and swag. We are proud to offer free shipping, responsive live chat, and expert design help.',
                image: 'https://coastalreign.com/images/sharedImage.png'
            };

            metaInfo = angular.extend({}, defaults, metaInfo);

            angular.element('meta[name=description]').attr('content', metaInfo.description);
            angular.element('meta[property="og:description"]').attr('content', metaInfo.description);
            angular.element('meta[property="og:image"]').attr('content', metaInfo.image);
            angular.element('meta[property="og:title"]').attr('content', metaInfo.title);
            $window.document.title = metaInfo.title;
        }

        // SEO
        function localeBusinessSchema(city) {
            var schema = {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Coastal Reign Printing",
                "image": [
                    "https://image.coastalreign.com/In+House+Screen+printing_1731112196082.jpg",
                    "https://image.coastalreign.com/About+Us+Banner+Photo_1691789095253.jpg",
                    "https://image.coastalreign.com/In+House+Production_1721839307987.jpg",
                    "https://image.coastalreign.com/staff%20image%20%202_1654016360648.jpg"
                ],
                "url": "https://coastalreign.com/",
                "description": "Coastal Reign is a custom clothing company based in Canada. Our team exists to provide high-quality screen printing, embroidery, direct-to-film printing, and sublimation on a large variety of custom clothing that allows brands, schools, and sports teams to stand out."
            };
            if (city === 'Vancouver') {
                schema.address = {
                    "@type": "PostalAddress",
                    "streetAddress": "8793 Cambie St",
                    "addressLocality": "Vancouver",
                    "addressRegion": "BC",
                    "postalCode": "V6P 3J9",
                    "addressCountry": "CA"
                };
                schema.openingHoursSpecification = [
                    {
                      "@type": "OpeningHoursSpecification",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                      ],
                      "opens": "09:00",
                      "closes": "17:00"
                    }
                  ];
                schema.geo = {
                    "@type": "GeoCoordinates",
                    "latitude": 49.20629067207281,
                    "longitude": -123.11759198650792
                };
                schema.hasMap = "https://www.google.com/maps/place/Coastal+Reign+Printing/@49.206168,-123.1201347,17z/data=!3m2!4b1!5s0x548674f0ea4fe15d:0x8cf3a5ded9a88fe4!4m6!3m5!1s0x5486769f38599947:0xfab5117abf828aea!8m2!3d49.2061645!4d-123.1175598!16s%2Fg%2F11b7cbswwg?entry=ttu";
                schema.telephone = "+1-604-900-5924";
            } else if (city === 'Toronto') {
                schema.address = {
                    "@type": "PostalAddress",
                    "streetAddress": "310 Judson St Unit 3",
                    "addressLocality": "Toronto",
                    "addressRegion": "ON",
                    "postalCode": "M8Z 5T6",
                    "addressCountry": "CA"
                };
                schema.openingHoursSpecification = [
                    {
                      "@type": "OpeningHoursSpecification",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                      ],
                      "opens": "09:00",
                      "closes": "17:00"
                    }
                  ]
                schema.geo = {
                    "@type": "GeoCoordinates",
                    "latitude": 43.6130283,
                    "longitude": -79.5144381
                };
                schema.hasMap = "https://www.google.com/maps/place/Coastal+Reign+Printing/@43.6130283,-79.5144381,15z/data=!4m6!3m5!1s0x882b49d23cccd757:0xebbcec353dd53df7!8m2!3d43.6130283!4d-79.5144381!16s%2Fg%2F11rq17cn5k?entry=ttu";
                schema.telephone = "+1-647-494-6266";
            } else if (city === 'Calgary') {
                schema.address = {
                    "@type": "PostalAddress",
                    "addressLocality": "Calgary",
                    "addressRegion": "AB",
                    "postalCode": "T2P 0P7",
                    "streetAddress": "903 8 Ave SW 9th Floor, Suite 900",
                    "addressCountry": "CA"

                };
                schema.geo = {
                    "@type": "GeoCoordinates",
                    "latitude": 51.0459601,
                    "longitude": -114.0817835
                };
                schema.hasMap = "https://www.google.com/maps/place/Coastal+Reign+Printing/@51.0459601,-114.0817835,15z/data=!4m2!3m1!1s0x0:0xf73991494c057f87?sa=X&ved=1t:2428&ictx=111";
                schema.telephone = "+1-888-210-3971";
            } else if (city === 'New York') {
                schema.address = {
                    "@type": "PostalAddress",
                    "addressLocality": "New York",
                    "addressRegion": "NY",
                    "postalCode": "10016",
                    "streetAddress": "136 Madison Avenue, #555-03",
                    "addressCountry": "US"

                };
                schema.geo = {
                    "@type": "GeoCoordinates",
                    "latitude": 40.7467174,
                    "longitude": -73.9928654
                };
                schema.hasMap = "https://www.google.com/maps/place/Coastal+Reign+Printing/@40.7467174,-73.9928654,15z/data=!3m1!5s0x89c25487d78692b3:0x60405634ab56b3a5!4m6!3m5!1s0x89c2593957bcef7f:0xd89dedda4cab9127!8m2!3d40.7462357!4d-73.9844308!16s%2Fg%2F11x2q7dcx7?entry=ttu&g_ep=EgoyMDI1MDIxOS4xIKXMDSoJLDEwMjExNDU1SAFQAw%3D%3D"
                schema.telephone = "+1-917-259-0231";
            }

            return schema;
        }

        function generateMainPageSchema() {
            return {
                "@context": "https://schema.org",
                "@graph": [
                    {
                        "@type": "Organization",
                        "additionalType": "Corporation",
                        "@id": "https://coastalreign.com/",
                        "description": "Looking for custom clothing and t-shirt printing in Canada? Coastal Reign offers a variety of custom clothing services with free shipping and expert design help.",
                        "name": "Coastal Reign",
                        "email": "sales@coastalreign.com",
                        "department": [
                            localeBusinessSchema('Vancouver'),
                            localeBusinessSchema('Calgary'),
                            localeBusinessSchema('Toronto'),
                            localeBusinessSchema('New York'),
                        ],
                        "sameAs": [
                            "https://www.facebook.com/coastalreignprint",
                            "https://twitter.com/coastalreign",
                            "https://www.linkedin.com/company/coastal-reign-printing-ltd",
                            "https://www.instagram.com/coastalreign/"
                        ],
                        "url": "https://coastalreign.com/",
                        "image": "https://image.coastalreign.com/coastal-reign-canada-logo.png",
                        "telephone": "1-888-210-3971",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": [
                                "+1-888-210-3971"
                            ],
                            "availableLanguage": [
                                "English"
                            ],
                            "contactType": "customer service",
                            "areaServed": {
                                "@type": "AdministrativeArea",
                                "address": {
                                    "@type": "PostalAddress",
                                    "addressCountry": [
                                        "CA", "US"
                                    ]
                                }
                            }
                        }
                    }
                ]
            }
        }

        function generateFaqTemplateSchema(faqSectionProps) {
            var faqSchema = {};
            if (faqSectionProps) {
                const questions = faqSectionProps.questions.dataProps.dataTypeList;
                const answers = faqSectionProps.answers.dataProps.dataTypeList;

                faqSchema = {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": questions.map((question, index) => ({
                        "@type": "Question",
                        "name": question.dataProps.textValue,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": answers[index].dataProps.richTextValue
                        }
                    }))
                };
            }
            return faqSchema;
        }

        function generateProductCollectionSchema(productListTitle, products) {
            var collectionPageSchema = {
                "@context": "https://schema.org",
                "@type": "CollectionPage",
                "name": productListTitle,
                "mainEntity": {
                    "@type": "ItemList",
                    "name": productListTitle,
                    "itemListElement": []
                }
            }

            var allProductSchemas = _.map(products, function(product) {
                return generateProductPLPSchema(product);
            })

            collectionPageSchema.mainEntity.itemListElement = allProductSchemas;

            return collectionPageSchema
        }


        function generateCodedPageSchema(pageMetadata) {
            // 1. Create and add base webpage schema
            const webPageSchema = {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "url": "https://coastalreign.com" + pageMetadata.pageUrl,
                "name": pageMetadata.metaTitle,
                "description": pageMetadata.metaDescription,
                "publisher": {
                    "@type": "Organization",
                    "name": "Coastal Reign",
                    "url": "https://coastalreign.com",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://coastalreign.com/logo.png"
                    }
                },
                "about": [
                    {
                        "@type": "Thing",
                        "name": "Custom Printed Clothing",
                        "sameAs": "https://www.wikidata.org/wiki/Q11460"
                    },
                    {
                        "@type": "Thing",
                        "name": "Promotional Products",
                        "sameAs": "https://en.wikipedia.org/wiki/Promotional_product"
                    },
                    {
                        "@type": "Thing",
                        "name": "Expert Design Help",
                        "sameAs": "https://en.wikipedia.org/wiki/Graphic_design"
                    },
                    {
                        "@type": "Thing",
                        "name": "Custom Apparel",
                        "sameAs": "https://en.wikipedia.org/wiki/Clothing"
                    },
                    {
                        "@type": "Thing",
                        "name": "T-Shirt Printing",
                        "sameAs": "https://en.wikipedia.org/wiki/T-shirt"
                    },
                    {
                        "@type": "Thing",
                        "name": "Screen Printing",
                        "sameAs": "https://en.wikipedia.org/wiki/Screen_printing"
                    },
                    {
                        "@type": "Thing",
                        "name": "Embroidery",
                        "sameAs": "https://en.wikipedia.org/wiki/Embroidery"
                    },
                    {
                        "@type": "Thing",
                        "name": "E-commerce",
                        "sameAs": "https://en.wikipedia.org/wiki/E-commerce"
                    },
                    {
                        "@type": "Thing",
                        "name": "Branding",
                        "sameAs": "https://en.wikipedia.org/wiki/Branding"
                    },
                    {
                        "@type": "Thing",
                        "name": "Customization",
                        "sameAs": "https://en.wikipedia.org/wiki/Customization"
                    }],
                "mainEntity": [],
                offers: {
                    '@type': 'Offer',
                    'shippingDetails': [
                        {
                            '@type': 'OfferShippingDetails',
                            'shippingRate': {
                                '@type': 'MonetaryAmount',
                                'value': '0.00',
                                'currency': 'USD'
                            },
                            'shippingDestination': {
                                '@type': 'DefinedRegion',
                                'addressCountry': 'US'
                            }
                        },
                        {
                            '@type': 'OfferShippingDetails',
                            'shippingRate': {
                                '@type': 'MonetaryAmount',
                                'value': '0.00',
                                'currency': 'CAD'
                            },
                            'shippingDestination': {
                                '@type': 'DefinedRegion',
                                'addressCountry': 'CA'
                            }
                        }
                    ]
                }
            };
            if (pageMetadata.primaryImage) {
                webPageSchema.primaryImageOfPage = {
                    "@type": "ImageObject",
                    "name": pageMetadata.metaTitle,
                    "url": pageMetadata.primaryImage,
                    "embedUrl": pageMetadata.primaryImage
                };
            }
            return webPageSchema;
        }

        function generatePageTemplateSchema(pageTemplate) {
            // 1. Create and add base webpage schema
            const webPageSchema = {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "url": "https://coastalreign.com/" + pageTemplate.pageUrl,
                "name": pageTemplate.metaInformation.metaTitle,
                "description": pageTemplate.metaInformation.metaDescription,
                "publisher": {
                    "@type": "Organization",
                    "name": "Coastal Reign",
                    "url": "https://coastalreign.com",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://coastalreign.com/logo.png"
                    }
                },
                "about": [
                    {
                        "@type": "Thing",
                        "name": "Custom Printed Clothing",
                        "sameAs": "https://www.wikidata.org/wiki/Q11460"
                    },
                    {
                        "@type": "Thing",
                        "name": "Promotional Products",
                        "sameAs": "https://en.wikipedia.org/wiki/Promotional_product"
                    },
                    {
                        "@type": "Thing",
                        "name": "Expert Design Help",
                        "sameAs": "https://en.wikipedia.org/wiki/Graphic_design"
                    },
                    {
                        "@type": "Thing",
                        "name": "Custom Apparel",
                        "sameAs": "https://en.wikipedia.org/wiki/Clothing"
                    },
                    {
                        "@type": "Thing",
                        "name": "T-Shirt Printing",
                        "sameAs": "https://en.wikipedia.org/wiki/T-shirt"
                    },
                    {
                        "@type": "Thing",
                        "name": "Screen Printing",
                        "sameAs": "https://en.wikipedia.org/wiki/Screen_printing"
                    },
                    {
                        "@type": "Thing",
                        "name": "Embroidery",
                        "sameAs": "https://en.wikipedia.org/wiki/Embroidery"
                    },
                    {
                        "@type": "Thing",
                        "name": "E-commerce",
                        "sameAs": "https://en.wikipedia.org/wiki/E-commerce"
                    },
                    {
                        "@type": "Thing",
                        "name": "Branding",
                        "sameAs": "https://en.wikipedia.org/wiki/Branding"
                    },
                    {
                        "@type": "Thing",
                        "name": "Customization",
                        "sameAs": "https://en.wikipedia.org/wiki/Customization"
                    }],
                "mainEntity": []
            };

            // Add preview image if exists
            const previewImage = $rootScope.compressImage(pageTemplate.previewImageUrl);
            if (previewImage) {
                webPageSchema.primaryImageOfPage = {
                    "@type": "ImageObject",
                    "name": pageTemplate.previewImageAltText,
                    "url": previewImage,
                    "embedUrl": previewImage
                };
            }

            // Add free shipping default offer
            webPageSchema.offers = {
                '@type': 'Offer',
                'shippingDetails': [
                    {
                        '@type': 'OfferShippingDetails',
                        'shippingRate': {
                            '@type': 'MonetaryAmount',
                            'value': '0.00',
                            'currency': 'USD'
                        },
                        'shippingDestination': {
                            '@type': 'DefinedRegion',
                            'addressCountry': 'US'
                        }
                    },
                    {
                        '@type': 'OfferShippingDetails',
                        'shippingRate': {
                            '@type': 'MonetaryAmount',
                            'value': '0.00',
                            'currency': 'CAD'
                        },
                        'shippingDestination': {
                            '@type': 'DefinedRegion',
                            'addressCountry': 'CA'
                        }
                    }
                ]
            };

            return webPageSchema;
        }

        function generateBreadcrumb(breadcrumbArray) {
            var breadcrumbStructuredListElement = _.map(breadcrumbArray, function (breadcrumbObject, index) {
                var pageUrl = (breadcrumbObject.pageUrl === '/')? '' : '/' + breadcrumbObject.pageUrl;
                return {
                    "@type": "ListItem",
                    "position": index + 1,
                    "name": PageTemplateService.cleanPageName(breadcrumbObject.targetKeyword),
                    "item": {
                        "name": PageTemplateService.cleanPageName(breadcrumbObject.targetKeyword),
                        "id": 'https://coastalreign.com' + pageUrl
                    }
                }
            });

            var breadcrumbData = {
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": breadcrumbStructuredListElement
            }

            return breadcrumbData;
        }

        function generateProductPLPSchema(productFromPLP) {
            var priceCurrency = 'CAD';
            var priceRegion = 'CA'
            var productPrice = productFromPLP.averagePrice;

            if ($rootScope.fromUS()) {
                priceCurrency = 'USD';
                priceRegion = 'US'
                productPrice = calculationService.ca2us(productPrice, priceCurrency);
            }
            // todo: update description to be product specific
            var productSchema =  {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": productFromPLP.name,
                "sku": productFromPLP.sku,
                "image": CommonUtilService.compressImage(productFromPLP.colours[0].front, 'image-sm'),
                "description": "Have your custom design printed on a " + productFromPLP.name + ". We offer free shipping and expert design help.",
                "brand": {
                    "@type": "Brand",
                    "name": productFromPLP.brand
                },
                "offers": {
                    "@type": "Offer",
                    "url": $location.protocol() + '://coastalreign.com' + productFromPLP.productUrl,
                    "priceCurrency": priceCurrency,
                    "price": productPrice.toFixed(2),
                },
            };

            var shippingDetails = [{
                "@type": "OfferShippingDetails",
                "name": "Standard Lead Time",
                "description": 'Free Standard Shipping',
                'shippingRate': {
                    '@type': 'MonetaryAmount',
                    'value': '0.00',
                    'currency': priceCurrency
                },
                'shippingDestination': {
                    '@type': 'DefinedRegion',
                    'addressCountry': priceRegion
                },
                "deliveryTime": {
                    "@type": "ShippingDeliveryTime",
                    "handlingTime": {
                        "@type": "QuantitativeValue",
                        "minValue": productFromPLP.standardLeadTime,
                        "maxValue": productFromPLP.standardLeadTime + calculationService.NON_GUARANTEED_EXTRA_DAYS,
                        "unitCode": "d"
                    },
                    "transitTime": {
                        "@type": "QuantitativeValue",
                        "minValue": 1,
                        "maxValue": 5,
                        "unitCode": "d"
                    }
                },
            }]

            if (productFromPLP.rushAvailable) {
                shippingDetails.push({
                    "@type": "OfferShippingDetails",
                    "name": "Rush Lead Time",
                    "description": 'Shipping rates vary by service selected',
                    "shippingRate": {
                        "@type": "MonetaryAmount",
                        "value": null,
                        "currency": priceCurrency
                    },
                    'shippingDestination': {
                        '@type': 'DefinedRegion',
                        'addressCountry': priceRegion
                    },
                    "deliveryTime": {
                        "@type": "ShippingDeliveryTime",
                        "handlingTime": {
                            "@type": "QuantitativeValue",
                            "minValue": productFromPLP.fastestLeadTime,
                            "maxValue": productFromPLP.slowestRushLeadTime,
                            "unitCode": "d"
                        },
                        "transitTime": {
                            "@type": "QuantitativeValue",
                            "minValue": 1,
                            "maxValue": 5,
                            "unitCode": "d"
                        }
                    }
                })
            }

            productSchema['offers'].shippingDetails = shippingDetails;

            return productSchema;
        }

        function generateProductGroupSchema(product, breadcrumbArray) {
            var category = _.find(categoryService.allCategories, function (cat) {
                return product.category == cat.name
            })
            var breadcrumbSchemaObject = generateBreadcrumb(breadcrumbArray);
            var priceCurrency = 'CAD'
            var offerCount = 1000;
            var lowCount = 25;
            try {
                 lowCount = calculationService.getStartingQuantityForProduct(product);
            } catch(e) {

            }
            var lowCountPrice = calculationService.calculateAveragePrice(product, lowCount, category).price
            var highCountPrice = calculationService.calculateAveragePrice(product, offerCount, category).price

            var localeUrl = ''
            if ($rootScope.fromUS()) {
                priceCurrency = 'USD';
                lowCountPrice = calculationService.ca2us(lowCountPrice, priceCurrency);
                highCountPrice = calculationService.ca2us(highCountPrice, priceCurrency);
                 localeUrl = '/en-us'
            }

            var brandName;
            if (product.brand === 'No Brand' || !product.brand) {
                brandName = 'Coastal Reign'
            } else {
                brandName = product.brand
            }
            var productGroup = {
                '@context': "https://schema.org/",
                "@type": "ProductGroup",
                'productGroupID': product.sku,
                "name": product.name,
                "sku": product.sku,
                "brand": {
                    "@type": "Brand",
                    "name": brandName
                },
                "offers": {
                    "@type": "AggregateOffer",
                    'url': $location.protocol() + '://coastalreign.com' + localeUrl + CommonUtilService.buildProductUrl(product),
                    "priceCurrency": priceCurrency,
                    "highPrice": lowCountPrice.toFixed(2),
                    "lowPrice": highCountPrice.toFixed(2),
                    "offerCount": offerCount,
                    "price": lowCountPrice.toFixed(2),
                    'availability': "https://schema.org/InStock"
                },
                "description": "Have your custom design printed on a " + product.name + ". We offer free shipping and expert design help.",
                'url': $location.protocol() + '://coastalreign.com' + localeUrl + CommonUtilService.buildProductUrl(product),
                'variesBy': [
                    "https://schema.org/size",
                    "https://schema.org/color"
                ]
            }


            function createProductVariant(product, colourSchema, size) {
                var variantName = colourSchema.name + ' ' + product.name + ' in ' + size;
                var variantImages = _.map(_.reject(
                    [colourSchema.front, colourSchema.back], function (imageUrl) {
                        return _.isEmpty(imageUrl)
                    }), function (image) {
                    return CommonUtilService.compressImage(image, 'image-sm')
                });
                var variantUrl = productGroup.url + '?selectedColour=' + encodeURI(colourSchema.name) + '&size=' + encodeURI(size);
                return {
                    '@type': "Product",
                    "sku": product.sku + '_' + CommonUtilService.replaceSpaceWithUnderscore(colourSchema.name) + '_' + CommonUtilService.replaceSpaceWithUnderscore(size),
                    // "inProductGroupWithID": product.sku,
                    "image": variantImages,
                    "name": variantName,
                    "description": "Have your custom design printed on a " + variantName,
                    "color": colourSchema.name,
                    "size": size,
                    "offers": {
                        "@type": "AggregateOffer",
                        "url": variantUrl,
                        "priceCurrency": priceCurrency,
                        "highPrice": lowCountPrice.toFixed(2),
                        "lowPrice": highCountPrice.toFixed(2),
                        "offerCount": offerCount,
                        "price": lowCountPrice.toFixed(2),
                        'availability': "https://schema.org/InStock"
                    }
                }
            }

            var variantsSchema = [];
            _.forEach(product.colours, function(colourSchema) {
                if (product.sizes.length === 1) {
                    variantsSchema.push(createProductVariant(product, colourSchema, 'One Size'))
                } else {
                    _.forEach(product.sizes, function (size) {
                        variantsSchema.push(createProductVariant(product, colourSchema, size))
                    })
                }
            })
            productGroup.hasVariant = variantsSchema;


            var productSchema = {
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": product.name,
                "sku": product.sku,
                "image": CommonUtilService.compressImage(product.colours[0].front, 'image-sm'),
                "description": "Have your custom design printed on a " + product.name + ". We offer free shipping and expert design help.",
                "brand": {
                    "@type": "Brand",
                    "name": product.brand
                },
                "offers": {
                    "@type": "AggregateOffer",
                    'url': $location.protocol() + '://coastalreign.com' + localeUrl + CommonUtilService.buildProductUrl(product),
                    "priceCurrency": priceCurrency,
                    "highPrice": lowCountPrice.toFixed(2),
                    "lowPrice": highCountPrice.toFixed(2),
                    "offerCount": offerCount,
                    "price": lowCountPrice.toFixed(2),
                    'availability': "https://schema.org/InStock"
                },
            };

            return [
                breadcrumbSchemaObject,
                productSchema,
                productGroup
            ]
        }

        function updateCanonicalTag(routeURL) {
            var head = document.getElementsByTagName('head')[0];
            var element = document.querySelector(`link[rel='canonical']`) || null
            if (element) {
                head.removeChild(element);
            }

            element = document.createElement('link');
            head.appendChild(element);
            element.setAttribute('rel', 'canonical')
            element.setAttribute('href', routeURL)
        }

        // baseUrl = canadian url
        function updateHrefLangTags(host, basePath) {
            var head = document.getElementsByTagName('head')[0];
            // 1) clear all hreflang elements first
            var elements = document.querySelectorAll(`link[hreflang]`)
            if (elements.length > 0) {
                for(var i = 0; i < elements.length; i ++) {
                    var hreflangElement = elements[i]
                    head.removeChild(hreflangElement);
                }
            }

            //2. create new tags given base url
            if (!_.isUndefined(basePath)) {
                var caUrl = host + basePath;
                if (basePath == '/') {
                    basePath = ''
                }
                var usUrl = host + '/en-us' + basePath;

                //1. x-default
                var defaultElement = document.createElement('link');
                head.appendChild(defaultElement);
                defaultElement.setAttribute('rel', 'alternate')
                defaultElement.setAttribute('hreflang', 'x-default')
                defaultElement.setAttribute('href', encodeURI(caUrl))

                //2. en-ca
                var enCAElement = document.createElement('link');
                head.appendChild(enCAElement);
                enCAElement.setAttribute('rel', 'alternate')
                enCAElement.setAttribute('hreflang', 'en-CA')
                enCAElement.setAttribute('href', encodeURI(caUrl))

                //3. en-us
                var enUSElement = document.createElement('link');
                head.appendChild(enUSElement);
                enUSElement.setAttribute('rel', 'alternate')
                enUSElement.setAttribute('hreflang', 'en-US')
                enUSElement.setAttribute('href', encodeURI(usUrl))
            }
        }

        function createMetaTag(key, identifier, content){
            var metas = document.getElementsByTagName("meta");
            var meta = _.find(metas, function(meta){
                return meta.getAttribute(key) == identifier
            });
            if (meta){
                meta.content = content
            } else {
                $('head').append('<meta '+ key + " = \"" + identifier + "\"" + ' content=\"' + content + '\" />');
            }
        }

        function updateRobotsTag(content) {
            // Default to "index, follow" if no content is provided
            content = content || "index, follow";
            
            var head = document.getElementsByTagName('head')[0];
            var robotsTag = document.querySelector('meta[name="robots"]');
            
            // If robots meta tag exists, update it
            if (robotsTag) {
                robotsTag.setAttribute('content', content);
            } 
            // Otherwise create a new one
            else {
                robotsTag = document.createElement('meta');
                robotsTag.setAttribute('name', 'robots');
                robotsTag.setAttribute('content', content);
                head.appendChild(robotsTag);
            }
        }

        return {
            generateMainPageSchema: generateMainPageSchema,
            localeBusinessSchema: localeBusinessSchema,
            generateCodedPageSchema: generateCodedPageSchema,
            generatePageTemplateSchema: generatePageTemplateSchema,
            generateProductCollectionSchema: generateProductCollectionSchema,
            generateBreadcrumb: generateBreadcrumb,
            generateProductPLPSchema: generateProductPLPSchema,
            generateFaqTemplateSchema: generateFaqTemplateSchema,
            generateProductGroupSchema: generateProductGroupSchema,
            updateCanonicalTag: updateCanonicalTag,
            updateHrefLangTags: updateHrefLangTags,
            updateRobotsTag: updateRobotsTag,
            createMetaTag: createMetaTag
        };
    }

})();
