(function () {
    'use strict';

    function isLocalStorageNameSupported(storageOverride) {
        var testKey = 'test'
        var storage = window.localStorage;
        if (storageOverride) {
            storage = storageOverride;
        }
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } catch (error) {
            return false;
        }
    }

    function insertPageNotFoundPrerenderTags() {
        var head = document.getElementsByTagName('head')[0];
        var element = document.querySelector(`meta[name='prerender-status-code']`) || null
        if (element) {
            head.removeChild(element);
        }

        var meta = document.createElement('meta');
        meta.setAttribute('name', "prerender-status-code");
        meta.setAttribute('content', '404');
        head.appendChild(meta);
    }

// dependency injection, root level
    var app = angular.module("app",
        [
            // third party
            'oc.lazyLoad',
            'ui.bootstrap',
            'ngRoute',
            'ngSanitize',
            // 'ngAnimate',
            'matchmedia-ng',

            // our own deps
            'sharedModule',
            'landingPageModule',
            'AuthService'
    ]
);


    app.config(['$routeProvider', '$locationProvider', '$ocLazyLoadProvider', 'matchmediaProvider', 'loggerProvider', '$compileProvider',function ($routeProvider, $locationProvider, $ocLazyLoadProvider, matchmediaProvider, loggerProvider, $compileProvider) {
        $compileProvider.debugInfoEnabled(false);

        $ocLazyLoadProvider.config({
            // DEBUGGING DEV ONLY - console log events
            // "debug": true,
            "events": true
        });
        // loggerProvider.setDEVMODE(true);

        // loading bar config
        matchmediaProvider.rules.phone = "(max-width: 767px)";
        matchmediaProvider.rules.tablet = "(max-width: 1023px)";
        matchmediaProvider.rules.desktop = "(min-width: 1024px)";

    $routeProvider

        // todo: research if this is safe to remove
        .when('/:locale?/!', {
            templateUrl: 'views/home.html',
            controller: 'MainController',
            resolve: {
                loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        'js/controllers/MainCtrl.js'
                    ]);
                }]
            }
        })

            .when('/:locale?/thank-you-for-your-order', {
                templateUrl: "views/non_bundled/ThankYouForYourOrder.html",
                controller: "ThankYouForYourOrderController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/promotions/ThankYouForYourOrderCtrl.js'
                        ]);
                    }],
                    robotsContentOverrides: function() {
                        return 'noindex, nofollow'
                    },
                }
            })

            .when('/:locale?/thank-you-for-booking', {
                templateUrl: "views/non_bundled/ThankYouForBooking.html",
                controller: "ThankYouForBookingController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/promotions/ThankYouForBookingCtrl.js'
                        ]);
                    }],
                    robotsContentOverrides: function() {
                        return 'noindex, nofollow'
                    },
                }
            })

            .when('/:locale?/sports-sponsorships', {
                templateUrl: "views/sports-sponsorships.html",
                controller: "SportSponsorController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/promotions/SportSponsorCtrl.js'
                        ]);
                    }],
                }
            })

            .when('/:locale?/contract-pricing', { // contract printing removed as of December 29th 2021 by eddison.
                templateUrl: "views/non_bundled/Contract.html",
                controller: "ContractCtrl",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            //todo: cachedproducts
                            'shared/js/services/cachedProducts.js',
                            // 'shared/js/services/calculate.js',
                            'js/controllers/ContractCtrl.js'
                        ], {
                            serie: true
                        });
                    }]
                }
            })

            .when('/:locale?/action/:actionType/:orderNumber/:zip', {
                templateUrl: "views/UpdateActionOnOrder.html",
                controller: "ActionController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/ActionCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/thank-you-for-contacting-us', {
                templateUrl: "views/non_bundled/ThankYouForContactingUs.html",
                controller: "ThankYouForContactingUsController",
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/ThankYouForContactingUsCtrl.js'
                        ]);
                    }],
                    robotsContentOverrides: function() {
                        return 'noindex, nofollow'
                    },
                    disableHrefLang: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/stock-colours', {
                templateUrl: 'views/non_bundled/SwatchColour.html',
                controller: 'SwatchController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'shared/js/services/printColoursService.js',
                            'js/controllers/one-off-pages/SwatchCtrl.js'
                        ]);
                    }]
                }
            })

        .when('/:locale?/review-request', {
            templateUrl: 'views/reviewRequest.html',
            controller: 'ReviewRequestController',
            resolve: {
                loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        'js/controllers/one-off-pages/ReviewRequestCtrl.js'
                    ]);
                }]
            }
        })


        .when('/:locale?/promotional-offer', {
            templateUrl: 'views/promotional-offer.html',
            resolve: {
                loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    ]);
                }]
            }
        })


            .when('/:locale?/contact', {
                templateUrl: 'views/contact.html',
                controller: 'ContactController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/ContactCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/sticker-printing', {
                templateUrl: 'views/vancouver-sticker-printing.html',
                controller: 'StickerPrintingController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/StickerPrintingCtrl.js'
                        ]);
                    }],
                    calculateBreadcrumbs: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/sign-up', {
                templateUrl: 'views/non_bundled/signUp.html',
                controller: 'SignUpCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/SignUpCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }
            })


            .when('/:locale?/design-help', {
                templateUrl: "views/design_help.html",
                controller: 'DesignHelpCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/design/DesignHelpCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/login', {
                templateUrl: "views/non_bundled/login.html",
                controller: 'LoginCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/LoginCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/sitemap', {
                templateUrl: 'views/non_bundled/SiteMap.html',
                controller: 'SitemapController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // todo: cachedproducts
                            'shared/js/services/cachedProducts.js',
                            // 'shared/js/services/calculate.js',
                            'shared/js/services/location.js',
                            'js/controllers/SiteMapCtrl.js'
                        ])
                    }]
                }
            })

            .when('/:locale?/stat-holidays', {
                templateUrl: 'views/non_bundled/StatHoliday.html',
                controller: 'StatHolidaysCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/StatHolidayCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/products/:productCategory/:productName', {
                templateUrl: 'views/product.html',
                controller: 'ProductCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'shared/libs/keen-slider/keen-slider.min.css',
                            'shared/libs/keen-slider/keen-slider.js',
                            'js/controllers/products-nav/ProductCtrl.js',
                        ]);
                    }],
                    calculateBreadcrumbs: function() {
                        return true
                    }
                },
                reloadOnSearch: false
            })

            .when('/:locale?/error', {
                templateUrl: 'views/404error.html'
            })

            .when('/:locale?/account', {
                templateUrl: 'views/non_bundled/account.html',
                controller: 'AccountCtrl',
                data: {
                    authRequired: true
                },
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/AccountCtrl.js'
                        ]);
                    }],
                    robotsContentOverrides: function() {
                        return 'noindex, nofollow'
                    },
                    disableHrefLang: function() {
                        return true
                    },
                }
            })

            .when('/:locale?/track-order', {
                templateUrl: 'views/non_bundled/track-order.html',
                controller: 'TrackOrderController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/TrackOrderCtrl.js'
                        ]);
                    }],
                    disableRecommendLocaleModal: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/designer/:productName', {
                templateUrl: 'views/non_bundled/Designer.html',
                controller: 'DesignerController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        loadingScreen('Loading Design Portal');
                        return $ocLazyLoad.load([
                            'js/controllers/design/DesignerCtrl.js'
                        ], {
                            serie: true
                        })
                            .finally(function () {
                                closeLoadingScreen();
                            })
                    }],
                    robotsContentOverrides: function() {
                        return 'noindex, follow'
                    },
                    calculateBreadcrumbs: function() {
                        return true
                    }
                },
                reloadOnSearch: false

            })

            .when('/:locale?/designer/:productName/:savedDesign', {
                templateUrl: 'views/non_bundled/Designer.html',
                controller: 'DesignerController',
                resolve: {
                    'fontLoaderService': function (fontLoaderService) {
                        return fontLoaderService.promise;
                    },
                    robotsContentOverrides: function() {
                        return 'noindex, follow'
                    },
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/design/DesignerCtrl.js'
                        ], {
                            serie: true
                        });
                    }],
                },

                reloadOnSearch: false
            })


            .when('/:locale?/cart', {
                templateUrl: 'views/cart.html',
                controller: 'CartController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/CartCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    },
                    robotsContentOverrides: function() {
                        return 'noindex, follow'
                    },
                }
            })

            .when('/:locale?/cart/load/:oldCartId', {
                templateUrl: 'views/cart.html',
                controller: 'CartController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/CartCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/custom-products/patches/woven', {
                templateUrl: 'views/custom-woven-patches.html',
                controller: 'WovenPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/WovenPatchesCtrl.js'
                        ]);
                    }],
                    calculateBreadcrumbs: function() {
                        return true
                    }
                }
            })


            .when('/:locale?/our-services/custom-embroidered-patches', {
                templateUrl: 'views/custom-embroidered-patches.html',
                controller: 'EmbroideredPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/EmbroideredPatchesCtrl.js'
                        ]);
                    }],
                    calculateBreadcrumbs: function() {
                        return true
                    }
                }
            })


            .when('/:locale?/custom-products/patches/leather', {
                templateUrl: 'views/custom-leather-patches.html',
                controller: 'LeatherPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/LeatherPatchesCtrl.js'
                        ]);
                    }],
                    calculateBreadcrumbs: function() {
                        return true
                    }
                }
            })

            .when('/:locale?/our-services/custom-sublimated-patches', {
                templateUrl: 'views/custom-sublimated-patches.html',
                controller: 'SublimatedPatchesCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            // 'shared/js/services/calculate.js',
                            'js/controllers/one-off-pages/our-services/SublimatedPatchesCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/reset', {
                templateUrl: 'views/non_bundled/reset.html',
                controller: 'ResetCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/account/ResetCtrl.js'
                        ], {
                            serie: true
                        });
                    }],
                    disableHrefLang: function() {
                        return true
                    }
                }

            })

            .when('/:locale?/privacy-policy', {
                templateUrl: 'views/non_bundled/privacy-policy.html'
            })

            .when('/:locale?/how-to-order', {
                templateUrl: 'views/how-to-order.html',
                controller: 'HowToOrderController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/HowToOrderCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/checkout', {
                templateUrl: 'views/non_bundled/checkout.html',
                controller: 'CheckoutController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/CheckoutCtrl.js'
                        ]);
                    }],
                    disableHrefLang: function() {
                        return true
                    },
                    robotsContentOverrides: function() {
                        return 'noindex, follow'
                    },
                    forceLocaleCheck: function() {
                        return true
                    }
                }
            })

            .when('/google5684cd0b08ed36b4.html', {
                templateUrl: "views/google5684cd0b08ed36b4"
            })

            .when('/:locale?/photo-submission', {
                templateUrl: "views/non_bundled/photo-submission.html",
                controller: 'PhotoController',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/PhotoCtrl.js'
                        ]);
                    }]
                }
            })

            .when('/:locale?/gallery', {
                templateUrl: "views/non_bundled/gallery.html",
                controller: 'GalleryCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/GalleryCtrl.js'
                        ]);
                    }]
                }
            })

        .when('/:locale?/custom-products/best-sellers', {
            templateUrl: "views/best-sellers.html",
            controller: 'BestSellersCtrl',
            resolve: {
                loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        'js/controllers/one-off-pages/BestSellersCtrl.js',
                    ]);
                }]
            }
        })

            .when('/:locale?/search', {
                templateUrl: 'js/controllers/one-off-pages/global-search.html',
                controller: 'GlobalSearchCtrl',
                resolve: {
                    loadModule: ['$ocLazyLoad', function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            'js/controllers/one-off-pages/GlobalSearchCtrl.js'
                        ]);
                    }],
                    robotsContentOverrides: function() {
                        return 'noindex, follow'
                    },
                },
                reloadOnSearch: false
            })

            .when('/:locale?/page-not-found', {
                templateUrl: "views/404error.html",
                resolve: {
                    prerenderNotFoundTag: function() {
                        insertPageNotFoundPrerenderTags();
                    }
                }
            })

             // catch the rest
            .otherwise({
                templateUrl: "js/controllers/custom-page-manager.html",
                controller: 'CustomPageManagerController',
                reloadOnSearch: false,
                resolve: {
                    loadModule: ['$ocLazyLoad', '$rootScope', function ($ocLazyLoad, $rootScope) {
                        return $ocLazyLoad.load([
                            'js/controllers/customPageManager.controller.js'
                        ])
                    }],
                    routePath: function(CountryService) {
                        return CountryService.getCurrentBasePath();
                    }
            }
         })

        $locationProvider.html5Mode(true);
    }]);

    app.filter('unsafe', function ($sce) {
        return $sce.trustAsHtml;
    });

// attaches token with every HTTP request made to server (for forbidden routes)
    app.config(function ($httpProvider) {
        $httpProvider.interceptors.push('AuthInterceptor');
    });

    app.run(['$rootScope', '$location', '$http', '$window', "$anchorScroll", 'CommonUtilService', 'SEOService', 'PageTemplateService', '$uibModalStack', 'AnalyticsService','$sce', '$cacheFactory', 'CustomerService', 'CountryService', '$routeParams', '$timeout', 'AuthToken', '$route', 'matchmedia', '$uibModalStack', function ($rootScope, $location, $http, $window, $anchorScroll, CommonUtilService, SEOService, PageTemplateService, $modalStack, AnalyticsService, $sce, $cacheFactory, CustomerService, CountryService, $routeParams, $timeout, AuthToken, $route, matchmedia, $uibModalStack) {
        $rootScope.initialContentLoaded = false;

        $anchorScroll.yOffset = 146;

        $rootScope.phoneNumber = {
            local: "604-900-5924",
            localToronto: "647-494-6266",
            localNewYork: "917-259-0231",
            localCalgary: "587-200-4270",
            tollFree: "1(888)-210-3971"
        }

         // Initialize locale before any route changes
        var path = $location.path();
        var firstSubdirectory = path.split("/")[1];
        var initialLocale = "";

        if (CountryService.isValidLocale(firstSubdirectory)) {
            initialLocale = firstSubdirectory;
        }

        // Set initial locale
        CountryService.setLocale(initialLocale, false);
    
        //https://github.com/angular-ui/bootstrap/issues/5810
        $rootScope.$watch(function() {
            return $('.modal').length;
        }, function(val) { // everytime the number of modals changes
            if (val > 0 && $uibModalStack.getTop()) {
                $uibModalStack.getTop().value.backdrop = 'static'; // disable default close behaviour
                $('.modal').on('mousedown', function(e) {
                    if (e.which === 1) { // left click
                        $uibModalStack.getTop().key.dismiss(); // close top modal when clicking anywhere, you can close all modals using $modalStack.dismissAll() instead
                    }
                });
                $('.modal-content').on('mousedown', function(e) {
                    e.stopPropagation(); // avoid closing the modal when clicking its body
                });
            }
        });

        $rootScope.pageNotFoundPrerender = function() {
            insertPageNotFoundPrerenderTags();
            if (navigator.userAgent.indexOf('Prerender') === -1 && document.location.pathname != "/page-not-found") {
                $location.path('/page-not-found');
            }
        }

        // remember images that have been loaded once
        $rootScope.lazyLoadedImagesCache = $cacheFactory('lazyLoadedImages');
        $rootScope.showSideNav = false;

        $rootScope.updateWithNewCartId = function(cartId,callback){
            $rootScope.verify(function(user){
                if (user){
                    $rootScope.updateCartWithUser(cartId, function(){
                        if (callback){
                            callback()
                        }
                    })
                } else {
                    localStorage.setItem("cartNew", cartId);
                    if (callback){
                        callback()
                    }
                }
            })
        }

        $rootScope.addItemLinesToCart = function (itemLines, products, callback) {
            AnalyticsService.trackAddItemsToCart(itemLines, products)
            $rootScope.getCartIdAndUser(function(user, cartId){
                if (cartId) {
                    // if cart is available, grab the cart and update the cart
                    $http.get('/Cart/' + cartId)
                        .success(function (response) {
                            for (var i = 0; i < itemLines.length; i++) {
                                response.listOfItems.push(itemLines[i])
                            }
                            $http.put('/Cart/' + cartId, response).success(function (updatedCart) {
                                $rootScope.updateWithNewCartId(updatedCart._id, function(){
                                    callback()
                                })
                            })
                                .error(function (response) {
                                    callback("ErrorCode:A Something went wrong when we added an item to your cart, please contact a representative and we will be happy to place your order.")
                                })
                        })
                        //if there is an invalid cart, create a new cart
                        .error(function (response) {
                            $http.post('/Cart', {listOfItems: itemLines}).success(function (response) {
                                $rootScope.updateWithNewCartId(response._id, function(){
                                    callback()
                                })
                            })
                                .error(function (response) {
                                    callback("ErrorCode:B Something went wrong when we added an item to your cart, please contact a representative and we will be happy to place your order.")
                                });
                        });
                } else { //there has not been a cart stored here before, create a new cart
                    $http.post('/Cart', {listOfItems: itemLines}).success(function (response) {
                        $rootScope.updateWithNewCartId(response._id, function(){
                            callback()
                        })
                    })
                        .error(function (response) {
                            callback("Something went wrong when we added an item to your cart, please contact a representative and we will be happy to place your order.")
                        });
                }
            })
        }

        // safari requires us to disable scroll event directly
        function disableScroll(scrollPosition) {
            document.body.style.overflow = 'hidden';
        }

        function enableScroll(scrollPosition) {
            document.body.style.removeProperty('overflow');
        }

        $rootScope.$watch('showSideNav', function () {
            $rootScope.showSideNav ? disableScroll() : enableScroll();
        })

        // $rootScope.loadLiveChatScript = function () {
        //     LiveChatWidget.on('ready', function () {
        //         $rootScope.chatInitialized = true;
        //         $rootScope.$apply();
        //         LiveChatWidget.call("maximize");
        //     })
        //     LiveChatWidget.init();
        //
        //     // var isLoaded = document.querySelectorAll('.ze-script');
        //     // if (isLoaded.length > 0) {
        //     //     zE('messenger', 'open');
        //     //     return;
        //     // }
        //     //
        //     // var myScript = document.createElement("script");
        //     // myScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=6ff0d6aa-068a-476c-8226-2a704d44ec4d';
        //     // myScript.id = "ze-snippet";
        //     // myScript.className = 'ze-script';
        //     // document.body.appendChild(myScript);
        //     // window.zdonload = setInterval(function() {
        //     //     if(typeof zE !== 'undefined') {
        //     //         clearInterval(window.zdonload);
        //     //         $rootScope.isChatBotLoaded = true;
        //     //         zE('messenger', 'open');
        //     //     }
        //     // }, 50)
        //
        // }

        $rootScope.getSupplierByName = function (name) {
            return _.find($rootScope.suppliers, function (supplier) {
                return supplier.name == name;
            })
        }

        var suppliersPromise = null

        $rootScope.getAllSuppliers = function () {
            if (!suppliersPromise) {
                suppliersPromise = $http.get('/api/suppliers/')
                    .then(function (response) {
                        $rootScope.suppliers = response.data
                        return response.data;
                    })
                    .catch(function (err) {
                        showError(err.data.message);
                        return $q.reject(err);
                    })
            }
            return suppliersPromise;
        }
        $rootScope.getAllSuppliers()


        // local : "604-327-1710",
        // tollFree : "1(800)-599-6404"

                //getcategorytext.js
        $rootScope.calculateAveragePrice = function (product, quantity) {
            var category = _.find(categoryService.allCategories, function(cat){
                return product.category == cat.name
            })
            return calculationService.calculateAveragePrice(product, quantity,category)
        }

        $rootScope.setLocalStorage = function (key, value, storageOverride) {
            var storage = storageOverride;
            if (!storageOverride) {
                storage = window.localStorage;
            }
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
            if (isLocalStorageNameSupported(storage)) {
                storage.setItem(key, value);
            } else {
                $rootScope[key] = value
            }
        }

        $rootScope.getLocalStorage = function (key, storageOverride) {
            var storage = storageOverride;
            if (!storageOverride) {
                storage = window.localStorage;
            }
            var item;
            if (isLocalStorageNameSupported(storage)) {
                item = storage.getItem(key);
            } else {
                item = $rootScope[key]
            }

            if (!item) {return;}

            if (item[0] === "{") {
                try {
                    item = JSON.parse(item)
                } catch(e){

                }
            }
            return item
        }

        $rootScope.getPreviewUrl = function (url) {
            if (url.includes('res.cloudinary')) {
                return url.substr(0, url.lastIndexOf(".")) + ".png";
            } else {
                return url;
            }
        }

        // todo: async call for suppliers
        $rootScope.getLeadTimeForProduct = function (product) {
            return getLeadTimeForProduct(product, $rootScope.suppliers)
        }

        $rootScope.getSwatchProductColour = function (colour) {
            if (colour) {
                return {
                    'background-color': colour.hexVal,
                    'border-color': colour.secondaryHexVal ? colour.hexVal : 'auto',
                    'color': colour.secondaryHexVal ? colour.secondaryHexVal : 'none',
                    'border-width': colour.secondaryHexVal ? '2px' : 'none',
                    'border-style': colour.secondaryHexVal ? 'solid' : 'none',
                    'background-image': colour.secondaryHexVal ? 'linear-gradient(to left top, currentColor,currentColor 49%,transparent 50%)' : 'none'
                }
            }
        }

        $rootScope.isPrerender = function() {
            return navigator.userAgent.toLowerCase().includes('prerender')
        }

        $rootScope.printHasAStandardColour = function (print) {
            return printColoursService.printHasStandardColour(print)
        }

        $rootScope.changeToCA = function (filter) {
            // if ($rootScope.getLocalStorage("visitedLocationPage") || filter && !$rootScope.isPrerender()) {
            //     $rootScope.setLocalStorage("visitedLocationPage", "Canada");
            // }
            // $rootScope.setLocalStorage("country", "CA");
            // $rootScope.visitingCountry = "Canada"
            CountryService.setLocale("", true);
        }

        $rootScope.getImagePreview = function(url, cloudinaryImgQty) {
            if (url.includes('res.cloudinary')) {
                var cloudinaryQuality = 'w_800';
                if (cloudinaryImgQty) {
                    cloudinaryQuality = cloudinaryImgQty
                }
                var pngUrl = url.substr(0, url.lastIndexOf(".")) + ".png";
                var indexOfUpload = pngUrl.indexOf("upload") + 7
                pngUrl = pngUrl.slice(0, indexOfUpload) + cloudinaryQuality + "/" + pngUrl.slice(indexOfUpload);
                return pngUrl
            } else {
                return url;
            }
        }

        $rootScope.testingCouponCode = function(){
            return window.testingCouponCode
                && !$rootScope.getLocalStorage("existingOrder")
                && (!$rootScope.user || ($rootScope.user && $rootScope.user.orders && $rootScope.user.orders.length == 0 ))
        }

        $rootScope.getCouponCodePricing = function(price){
            return price * .95;
        }

        $rootScope.getProofPreview = function (itemLine, cloudinaryImgQty) {
            if (itemLine.proof) {
                return $rootScope.getImagePreview(itemLine.proof, cloudinaryImgQty);
            } else {
                return itemLine.mockup[0];
            }
        }

        $rootScope.onlyEmbroideryAllowed = function (category) {
            return calculationService.onlyEmbroideryAllowed(category)
        }

        // todo: refactor usage in templates
        $rootScope.fromUS = function () {
            return CountryService.getCurrentLocale() === 'en-us';
            // return $rootScope.getLocalStorage("country") == "US"
        }

        // todo: refactor usage in templates
        $rootScope.fromCA = function () {
            return !CountryService.getCurrentLocale();
            // return $rootScope.getLocalStorage("country") == "CA";
        }

        $rootScope.getCurrentLocale = function() {
            return CountryService.getCurrentLocale();
        }

        $rootScope.visitingCountry = function(codeOnly, localeToCheck) {
            var currentLocale;
            if (!_.isUndefined(localeToCheck)) {
                currentLocale = localeToCheck
            } else {
                currentLocale = CountryService.getCurrentLocale()
            }
            switch (currentLocale) {
                case 'en-us': if (codeOnly) { return "U.S" } else {return "the United States"}
                    break
                default: if (codeOnly) {return "CA" } else { return "Canada"}
            }
        }

        //todo: refactor
        $rootScope.changeToUS = function () {
            // $rootScope.setLocalStorage("visitedLocationPage", " ");
            // $rootScope.setLocalStorage("country", "US");
            // $rootScope.visitingCountry = "the United States"
            CountryService.setLocale('en-us', true);
        }


        $rootScope.changeToCountry = function (country) {
            // set timeout prevents flicker as page reloads
            $rootScope.showRecommendLocaleModal = false;
            setTimeout(function() {
                if (country == "CA" || country == '') {
                    $rootScope.changeToCA()
                }
                if (country == "US" || country == 'en-us') {
                    $rootScope.changeToUS()
                }
            }, 0)
        }

        $rootScope.orderCurrency = function(value, currencyObject) {
            if (currencyObject) {
                return calculationService.getCurrencyValue(value, currencyObject);
            } else {
                'N/A'
            }
        }

        //current currency for any value, not tied to order
        $rootScope.currency = function(value) {
            var currency;
            switch(CountryService.getCurrentLocale()) {
                case 'en-us':
                    currency = modelService.ORDER_CURRENCY.USD;
                    break;
                case '':
                    currency = modelService.ORDER_CURRENCY.CAD
                    break;
                default:
                    currency = modelService.ORDER_CURRENCY.CAD
                    break;
            }
            var amount = calculationService.ca2us(value, currency);
            return '$' + amount.toFixed(2) + ' ' + currency;
        }

        $rootScope.createTicket = function (ticketInfo, callback) {
            $http.post('/CreateTicket', ticketInfo)
                .success(function (messageId, error) {
                    callback(messageId, false)
                })
                .error(function () {
                    callback(false, true)
                })
        }

        /* Should be encoded (handles white spaces) */
        $rootScope.getTrackOrderLink = function (order) {
            if (!_.isEmpty(order)) {
                return getTrackOrderLink(order)
            } else return '';
        }

        $rootScope.getRecentTracking = function () {
            return localStorage.getItem("orderUrl")
        }

        $rootScope.getOrderStatus = function (order) {
            return getOrderStatus(order)
        }

        $rootScope.orderConfirmed = function (order) {
            var status = getOrderStatus(order)
            return status == "Printing" || status == "Shipped"
        }

        $rootScope.getLastStatusDate = function (order) {
            return getLastStatusDate(order)
        }


        $rootScope.encodeURI = function (str) {
            return encodeURI(str).toLowerCase()
        }

        $rootScope.decodeURI = function (str) {
            return decodeURI(str).toLowerCase()
        }

        $rootScope.breadcrumb = {
            list: [],
            isFetching: false
        };
        $rootScope.recommendLocale;

        // set min viewheight to prevent flickering as custom-page-manager loads
        //https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        $rootScope.setVh = function() {
            var vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        $rootScope.$on('$routeChangeStart', function (event, next, current) {
            $modalStack.dismissAll();
            $('.modal-backdrop').remove();
            $('body').removeClass("modal-open");
            var routeLocale = next.params.locale;
            // todo: hack locale param not piped to custompagemanager, need to peek url
            if (!next.$$route) {
                var firstSubdirectory = $location.path().split("/")[1]
                if (CountryService.isValidLocale(firstSubdirectory)) {
                    routeLocale = firstSubdirectory;
                }
            }

            if (!routeLocale) {
                routeLocale = ""; // default to en-ca
            }

            // on initial app load only
            var disableRecommendLocaleModal = false;
            var forceLocaleCheck = false;
            try {
                if (next.resolve.disableRecommendLocaleModal) {
                    disableRecommendLocaleModal = next.resolve.disableRecommendLocaleModal()
                }
                if (next.resolve.forceLocaleCheck) {
                    forceLocaleCheck = next.resolve.forceLocaleCheck();
                }
            } catch (e) {

            }
            if (!current) {
                try {
                    $rootScope.setVh();
                    CountryService.validatePageLocale(forceLocaleCheck, disableRecommendLocaleModal);
                } catch (e) {
                    console.log(e);
                    $location.path('/page-not-found')
                }
            } else {
                document.getElementById("footer-container").style.display = 'none'
                // internal navigation
                // url changes via $location.path() needs to respect locale
                var currentLocale = CountryService.getCurrentLocale();
                if (next !== current && currentLocale) {
                    if (routeLocale !== currentLocale) {
                        event.preventDefault();
                        // need to cancel url change and update  w.r.t locale
                        CountryService.reloadUrl();
                    }
                }

                if (forceLocaleCheck) {
                    CountryService.validatePageLocale(forceLocaleCheck, disableRecommendLocaleModal);
                }
            }

            if (next.data && next.data.authRequired) {
                if (!AuthToken.isLoggedIn()){
                    event.preventDefault();
                    $location.path('/login');
                }
            }
        });

        // clicked close on the modal
        $rootScope.userConfirmLocale = function() {
            $rootScope.showRecommendLocaleModal = false
            $rootScope.setLocalStorage('userConfirmedLocation', Date.now() + (60000 * 60 * 24 * 5));
        }

        // initial page load
        $rootScope.$on('$viewContentLoaded', function () {
            // page manager handles it in controller
            if ($route.current.loadedTemplateUrl !== "js/controllers/custom-page-manager.html") {
                // page loaded gtag event
                if (window.dataLayer && !$rootScope.initialViewContentLoaded) {
                    setTimeout(function(){
                        window.dataLayer.push({
                            'event': 'initial_view_content_loaded'
                        });
                    }, 5000)
                 $rootScope.initialViewContentLoaded = true;
                }
                // add after - cls
                setTimeout(function() {
                    document.getElementById("footer-container").style.display = 'block';
                })
                //Here your view content is fully loaded !!
            }
        });

        // matchmedia.onPhone(function(mediaQuery) {
        //     shouldShowStoryPage();
        // })
        // $window.onresize = shouldShowStoryPage;

        function shouldShowStoryPage() {
            $rootScope.showStoryPage = ($location.path() === "/" || $location.path() === '/en-us') && window.innerWidth < 767;
        }

        $rootScope.showStoryPage;
        $rootScope.$on('$routeChangeSuccess', function (event, next, current) {
            shouldShowStoryPage();
            // on route change scroll to top of page
            $anchorScroll();
            var navMain = $("#bs-example-navbar-collapse-1");
            navMain.collapse('hide');
            // home page
            var urlPath = CountryService.getCurrentBasePath().substring(1);
            if (!urlPath) {
                $rootScope.breadcrumb.list = []
                // delegate to CustomPageMananger has no route OR hardcoded route requires fetching
            } else if (!next.$$route || next.locals.calculateBreadcrumbs) {
                $rootScope.breadcrumb.isFetching = true;
            } else {
                $rootScope.breadcrumb.list = [];
            }

            // update canonical element
            var host = $location.protocol() + '://' + location.host;
            // no query params
            var fullUrl = encodeURI(host + $location.path());
            SEOService.updateCanonicalTag(fullUrl);

            var basePath;
            if (!next.locals.disableHrefLang) {
                basePath = CountryService.getCurrentBasePath();
            }
            SEOService.updateHrefLangTags(host, basePath, $location.search());

            // Set robots meta tag, override if supplied
            var robotsContent = null;
            if (next.locals.robotsContentOverrides) {
                robotsContent = next.locals.robotsContentOverrides;
            }
            SEOService.updateRobotsTag(robotsContent);
        });

        $rootScope.smoothScrollTo = function (target, animationTimeOverride) {
            var animationMs = 750;
            if (animationTimeOverride >= 0) {
                animationMs = animationTimeOverride;
            }
            var $target = $(target);
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, animationMs, 'swing', function () {

            });
        }

        $rootScope.signUp = function (user, callback) {
            $http.post('/User/', user)
                .success(function (userToken) {
                    AnalyticsService.customTrackSignUp(user.username)
                    callback(userToken)
                })
                .error(function () {
                    callback()
                })
        }

        $rootScope.getProductCategoryUrl = function(product) {
            //getcategorytext.js
            var foundCategory = _.find(categoryService.allCategories, function(cat) {
                return cat.name === product.category
            });

            var breadcrumbUrl = '/custom-products';

            if (foundCategory.catUrlMap && product.fit) {
                var fitToTest = product.fit.toLowerCase();
                var urlToTest = foundCategory.catUrlMap[fitToTest];
                    if (!urlToTest) {
                        if (fitToTest === 'toddler') {
                            urlToTest = foundCategory.catUrlMap['youth'];
                        } else {
                            // default
                            urlToTest = foundCategory.catUrlMap['unisex'];
                        }
                    }
                    if (urlToTest) {
                        breadcrumbUrl = urlToTest;
                    }
                }

            if (foundCategory &&  foundCategory.categoryUrl) {
                breadcrumbUrl = foundCategory.categoryUrl
            }
            return breadcrumbUrl;
        }

        // determine best guess breadcrumb from product metadata
        $rootScope.getBreadcrumbFromProduct = function(product) {
            var breadcrumbUrl = $rootScope.getProductCategoryUrl(product);
            breadcrumbUrl = breadcrumbUrl.substring(1);
            var productUrlWithLocale = CommonUtilService.buildProductUrl(product);
            var currentLocale = CountryService.getCurrentLocale();
            if (currentLocale) {
                productUrlWithLocale = '/' + currentLocale + productUrlWithLocale;
            }
                return PageTemplateService.getBreadcrumb(breadcrumbUrl, $rootScope.breadcrumb)
                .then(function(breadcrumbRes) {
                    if (breadcrumbRes.url === breadcrumbUrl) {
                        breadcrumbRes.breadcrumb.unshift({
                            targetKeyword: CommonUtilService.toTitleCase(product.name),
                            // need current url path to handle locale
                            pageUrl: productUrlWithLocale.substring(1)
                        })
                        $rootScope.breadcrumb.list = breadcrumbRes.breadcrumb
                        return breadcrumbRes.breadcrumb;
                    }
                })
                    .catch(function(e) {
                        $rootScope.breadcrumb = null;
                    })
                    .finally(function() {
                        $rootScope.breadcrumb.isFetching = false;
                    })
        }

        //getcategorytext.js
        $rootScope.findCategoryFromString = function(categoryString){
            function replaceHyphenWithStrings(inputText, replacementString) {
                var replacedText = inputText.replace(/-/g, replacementString);
                return replacedText;
            }

            var category = _.find(categoryService.allCategories, function(category){
                var matching = replaceHyphenWithStrings(categoryString," ").toLowerCase()
                return matching == replaceHyphenWithStrings(category.name, " ").toLowerCase()
            })

            if (!category){
                category = categoryService.defaultCategory;
            }
            return category
        }

        //todo: split up this endpoint
        $rootScope.verify = function (callback) {
            if (window.localStorage.userToken){
                $http.post('/Verify', {token: window.localStorage.userToken})
                    .success(function (user) {
                        callback(user)
                    })

                    .error(function () {
                        callback(false)
                    })

            } else {
                callback(false)
            }
        }


        $rootScope.addLocaleToJotForm = function(jotformurl){
            if($rootScope.fromUS()){
                jotformurl = jotformurl + "?local=en-us/"
            }
            return jotformurl
        }


        $rootScope.getDesignHelpForm = function(){
            return $rootScope.addLocaleToJotForm("https://form.jotform.com/231234543785256")
        }

        $rootScope.compressImage = function (url) {
            return CommonUtilService.compressImage(url);
        }

        $rootScope.getJotFormUrlFromProduct = function(product, productColour){
            var url = "https://form.jotform.com/230964611728258?w=" + encodeURI(product.name)

             if (productColour){
                 url = url + "&whatColour=" + encodeURI(productColour)
             }
            return $sce.trustAsResourceUrl(url)
        }

        $rootScope.getProductImage = function(product,colourIndex,view){
            if (product && product.colours[colourIndex]&& product.colours[colourIndex][view]) {
                return product.colours[colourIndex][view];
            } else {
                return false
            }
        }

        $rootScope.getProductFront = function (product, index) {
            if (product && product.colours[index].front) {
                return product.colours[index].front;
            }
        }

        $rootScope.getProductBack = function (product, index) {
            if (product && product.colours[index].back) {
                return product.colours[index].back;
            }
        }

        $rootScope.getPromoDecorationGroupsForProduct = function (product) {
            var printTypes = [];
            _.forOwn(calculationService.promoProductDecorationGrouping, function (grouping, key) {
                if (_.some(grouping.list, function (printName) {
                    return product.promoPrintMethods.includes(printName)
                })) {
                    printTypes.push(grouping.name)
                }
            });
            return printTypes;
        }


        $rootScope.codeLogin = function (username, oneTimeCode, callback) {
            var request = {
                username: username,
                oneTimeCode: oneTimeCode
            }

            $http.post('/AuthenticateUserWithCode', request)
                .success(function (response) {
                    window.localStorage.userToken = response.token;
                    callback("true")
                })
                .error(function (response) {
                    callback(false)
                });
        }

        $rootScope.deleteUpload = function(upload, accountUploads){
            $http.post('/DeleteUpload', {token: window.localStorage.userToken, uploadId : upload._id})
                .success(function () {
                    var uploadIndex = _.findIndex(accountUploads, function(existingUpload){
                        return existingUpload._id == upload._id
                    })
                    accountUploads.splice(uploadIndex,1)
                })
                .error(function () {
                    showError("Could not delete design, please try again later.")
                })
        }

        $rootScope.truncateText = function(str, length){
            if (str.length > length) {
                return str.substring(0, length - 3) + '...';
            } else {
                return str;
            }
        }

        $rootScope.getUserToken = function(){
            return window.localStorage.userToken
        }

        $rootScope.setToken = function(token){
            window.localStorage.userToken = token;
        }


        $rootScope.login = function (username, password, callback) {
            var request = {
                username: username,
                password: password
            }

            $http.post('/AuthenticateUser', request)
                .success(function (response) {
                    $rootScope.setToken(response.token)
                    callback("true")
                })

                .error(function (response) {
                    callback(false)
                });
        }

        $rootScope.getProductUrl = function (product) {
            return CommonUtilService.buildProductUrl(product)
        }


        $rootScope.goToProduct = function (product) {
            $location.path(CommonUtilService.buildProductUrl(product)).search({});
        }

        $rootScope.getCartTotal = function (cart) {
            var cartTotal = 0;
            if (cart) {
                for (var i = 0; i < cart.length; i++) {
                    cartTotal = (cartTotal + cart[i].price * cart[i].totalQty);
                }
            }
            return cartTotal

        }

        $rootScope.updateCartWithUser = function(cartId,callback){
            CustomerService.updateCart(cartId)
                .then(function() {
                    if(callback){
                        callback()
                    }
                });
        }



        $rootScope.getCartIdAndUser = function(callback){
            $rootScope.verify(function(user) {
                var localCart = $rootScope.getLocalCartId();
                if (user){
                    if (user.currentCart){
                        callback(user,user.currentCart)
                    } else if (localCart){
                        $rootScope.updateCartWithUser(localCart, function(){
                            localStorage.setItem("cartNew", "")
                            callback(user,localCart)
                        })
                    } else {
                        callback(user,localCart)
                    }
                } else {
                    callback(false,localCart)
                }
            })
        }

        $rootScope.getLocalCartId = function () {
            var cartId = localStorage.getItem("cartNew");
            return cartId
        }

        $rootScope.refreshUserAndCartId = function(){
            $rootScope.getCartIdAndUser(function(user,cartId){
                if (user) {
                    $rootScope.user = user;
                }
                $rootScope.cartId = cartId
            })
        }

        $rootScope.refreshUserAndCartId()



        $rootScope.updateCartInDb = function (cart, callback) {
            $http.put('/Cart/' + cart._id, cart)
                .success(function (response) {
                    callback(true)
                })
                .error(function () {
                    callback(false)
                })
        }


        $rootScope.getCart = function (cartId, callback) {
            if (cartId) {
                $http.get('/Cart/' + cartId).success(function (response) {
                    if (!response) {
                        callback({listOfItems: []})
                    } else {
                        callback(response)
                    }

                })
                    .error(function () {
                        callback({listOfItems: []})
                    });
            } else {
                callback({listOfItems: []})
            }
        }

    }]);

    /*
     * Max length on all textareas
     */
    app.directive('textarea', function() {
        return {
            restrict: 'E',
            link: function($scope, $element, attrs) {
                if (!attrs.maxlength) {
                    attrs.$set('maxlength', 35000); // default
                }
            }
        };
    });

    /*
  * Default Maximums on all inputs
  */
    app.directive('input', function() {
        return {
            restrict: 'E',
            link: function($scope, $element, attrs) {
                if (attrs.type) {
                    if (attrs.type === 'text' || attrs.type === 'email') {
                        if (!attrs.maxlength) {
                            attrs.$set('maxlength', 250); // default
                        }
                    } else if (attrs.type === 'number') {
                        if (!attrs.max) {
                            attrs.$set('max', 100000)
                        }
                    }
                }
            }
        };
    });

    // refresh all anchor elements with locale and relative domain
    app.directive('a', ['CountryService', function(CountryService) {
        return {
            restrict: 'E',
            scope: {
                anchorCanUpdate: '=?'
            },
            link: function($scope, element, attrs) {
                if (CountryService.getCurrentLocale === "") {
                    // do nothing default
                } else {
                    var localeDeregister = $scope.$on('localeReady', function () {
                        updateHref();
                    })

                    var hrefSetDeregister = attrs.$observe('href', function () {
                        updateHref();
                    });
                }

                function updateHref() {
                    if ((attrs.href !== '#' && attrs.href && !_.isUndefined(CountryService.getCurrentLocale()))) {
                        // pass in absolute url from anchor context
                       var hrefToUpdate = CountryService.getRelativeLocaleUrl(element.context.href);
                       // set the first time then deregister
                        if (attrs.href !== hrefToUpdate) {
                            if (!$scope.anchorCanUpdate) {
                                hrefSetDeregister();
                                localeDeregister();
                            }
                            setTimeout(function(){
                                attrs.$set('href', hrefToUpdate);
                                // console.log(element.context.href)
                            })
                        }
                    }
                }
            }
        }
    }]);

    app.directive('loginForm', function () {
        return {
            restrict: 'E',
            scope: {
                onLoginSuccess: '&',
                email: '=',
                signUp : '='
            },
            templateUrl: '../../views/non_bundled/login-screen-directive.html',
            controller: ['$scope', '$http', '$location', '$rootScope', function ($scope, $http, $location, $rootScope) {

                $scope.$watch('email', function(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        $scope.user.username = newVal || '';
                    }
                });

                $scope.user = {
                    username : $scope.email
                }
                $scope.login = function () {
                    $rootScope.login($scope.user.username, $scope.user.password, function (success) {
                        if (success) {
                            $rootScope.refreshUserAndCartId();
                            $scope.onLoginSuccess();
                        } else {
                            showError("Username and password combination is incorrect");
                        }
                    });
                };

                $scope.codeLoginForm = function () {
                    loadingScreen("Verifying Code");
                    $rootScope.codeLogin($scope.user.username, $scope.oneTimeCode, function (success) {
                        if (success) {
                            closeLoadingScreen();
                            $rootScope.refreshUserAndCartId();
                            $scope.onLoginSuccess();
                        } else {
                            closeLoadingScreen();
                            showError("Code Incorrect");
                        }
                    });
                };

                $scope.forgotPassword = function () {
                    $scope.forgotPasswordScreen = true;
                    $scope.loginPassword = false;
                };

                $scope.loginWithPassword = function () {
                    $scope.oneTimeCode = "";
                    $scope.loginPassword = true;
                    $scope.loginCode = false;
                    $scope.forgotPasswordScreen = false;
                };

                $scope.sendOneTimeCode = function () {
                    var request = {
                        username: $scope.user.username
                    };

                    loadingScreen("Sending Code");
                    $http.post('/SendOneTimeCode', request)
                        .success(function () {
                            closeLoadingScreen();
                            $scope.oneTimeCodeSent = true;
                        })
                        .error(function (token) {
                            closeLoadingScreen();
                            showError("Sorry, the email you entered is not valid.");
                        });
                };

                $scope.loginWithOneTimeCode = function () {
                    $scope.loginPassword = false;
                    $scope.loginCode = true;
                    $scope.oneTimeCode = "";
                    $scope.user.username = "";
                    $scope.oneTimeCodeSent = false;
                };

                $scope.loginWithPassword();

                $scope.resetPassword = function () {
                    if (!$scope.user.username) {
                        showError("Please enter your email and then proceed to clicking \"Forgot Password\"");
                        return;
                    }
                    loadingScreen("Resetting...");
                    var request = {
                        username: $scope.user.username
                    };

                    $http.post('/ForgotPassword', request)
                        .success(function (token) {
                            closeLoadingScreen();
                            success("If your email is in our database, a link will be sent to reset your password.");
                            $scope.forgotPasswordScreen = false;
                            $scope.loginPassword = true;
                        })
                        .error(function (token) {
                            closeLoadingScreen();
                            success("If your email is in our database, a link will be sent to reset your password.");
                            $scope.forgotPasswordScreen = false;
                            $scope.loginPassword = true;
                        });
                };
            }]
        };
    });

    app.directive('onErrorSrc', function() {
        return {
            link: function(scope, element, attrs) {
                element.bind('error', function() {
                    if (attrs.src != attrs.onErrorSrc) {
                        attrs.$set('src', attrs.onErrorSrc);
                    }
                });
            }
        }})

    app.directive('cantEmpty', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {
                function isEmpty(value) {
                    return !value || !value.trim();
                }

                function setInvalid() {
                    if (element.is('input[type="radio"]')) {
                        element.parent().parent().css({
                            'border': '2px solid #a94442',
                            'border-radius': '5px'
                        });
                    } else {
                        element.css({
                            'border': '2px solid #a94442',
                            'border-radius': '5px',
                        });
                    }

                    // Scroll to the element
                    element[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

                function setValid() {
                    if (element.is('input[type="radio"]')) {
                        element.parent().parent().css({
                            'border': '',

                        });
                    } else {
                        element.css({
                            'border': '',
                        });
                    }
                }


                function validate() {
                    ngModelCtrl.$setValidity('cantEmpty', !isEmpty(ngModelCtrl.$viewValue));
                    if (!ngModelCtrl.$valid) {
                        setInvalid();
                    } else {
                        setValid();
                    }
                }

                // Validate on form submission
                scope.$on('formSubmitted', function() {
                    validate();
                });

                // Validate on input change
                element.on('input', function() {
                    scope.$apply(function() {
                        validate();
                    });
                });

                element.on('change', function() {
                    scope.$apply(function() {
                        validate();
                    });
                });
            }
        };
    });

    app.directive('valuesCheckBox', ['CommonUtilService',  function (CommonUtilService) {
        return {
            templateUrl: '../../views/values-checkbox-directive.html',
            scope: {
                prefix: '@prefix',
                titleheader: '@titleheader',
                aprefix: '@aprefix',
                postorder: '@postorder'
            },
            link: function ($scope) {

            }
        };
    }]);

    app.directive('clients', function () {
        return {
            templateUrl: 'views/clients.html',
            link: function () {
            }
        }
    });

    app.directive("ngMobileClick", [function () {
        return function (scope, elem, attrs) {
            elem.bind("touchstart click", function (e) {
                e.preventDefault();
                e.stopPropagation();

                scope.$apply(attrs["ngMobileClick"]);
            });
        }
    }])
})();