(function () {
    'use strict';

    angular.module('app')
        .directive('localeFindAndReplace', LocaleFindAndReplaceDirective);

    LocaleFindAndReplaceDirective.$inject = ['$compile', 'CountryService']

    function LocaleFindAndReplaceDirective($compile, CountryService) {

        var directive = {
            link: link,
            restrict: 'A',
        };
        return directive;

        function link(scope, element, attrs) {
            var ensureCompileRunsOnce = scope.$watch(
                function(scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.localeFindAndReplace);
                },
                function(value) {

                    var updatedText = CountryService.textFindAndReplace(value);

                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(updatedText);
                    // Remove the attribute after processing
                    element.removeAttr('locale-find-and-replace');
                    // Use un-watch feature to ensure compilation happens only once.
                    ensureCompileRunsOnce();
                }
            );
        }
    }
})();